import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router";
import InputButton from '../FormComponents/InputButton/index';
import { PoundCurrency } from '../EncodingManipulation';
import { paymentUrl } from '../../../lib/api/payments';

const PaymentAmountChoice = ({ accountBalance }) => {
    const [otherAmount, setOtherAmount] = useState("5.00")
    const [option, setOption] = useState("account-balance")
    const [submitting, setSubmitting] = useState(false)
    const [amountValidationError, setAmountValidationError] = useState()
    const history = useHistory()
    const location = useLocation()

    const onCustomAmountSelected = () => {
        if (window?.mixpanel?.track) {
            window.mixpanel.track("Pay Now Custom Amount Selected")
        }
        setOption("other-amount")
    }

    const onAccountBalanceSelected = () => {
        if (window?.mixpanel?.track) {
            window.mixpanel.track("Pay Now Account Balance Selected")
        }
        setOption("account-balance")
    }

    const submitChoice = async (event) => {
        event.preventDefault();
        if (submitting) {
            console.warn("submit clicked again while already submitting")
            return
        }

        const minAmount = 5.00;
        let payAmount = 0;
        if (option == "account-balance") {
            payAmount = accountBalance.toString();
        } else {
            payAmount = otherAmount
        }
        if (!(parseFloat(payAmount) >= minAmount)) {
            console.error(`pay amount must be greater than ${minAmount}`);
            setAmountValidationError(`Payment must be greater than &pound;${minAmount}`);
            return null;
        } else if (payAmount > accountBalance) {
            console.error(`pay amount must be less than ${accountBalance}`);
            setAmountValidationError(`Payments must not be greater than the account balance`);
            return null;
        } else {
            if (amountValidationError) setAmountValidationError(null);
        }

        setSubmitting(true);

        await paymentUrl({ payAmount })
            .then(res => {
                if (!res.data.data.redirectUrl) {
                    throw new Error("redirectUrl is not present in the returned json")
                }
                let forwardTo = res.data.data.redirectUrl;
                history.push('/cardpayment', { targetUrl: forwardTo, from: location.pathname })
            })
            .catch(err => {
                console.log(err)
                setSubmitting(false)
            })
    }

    return (
        <form className="bar w-1/2 sm:w-full mx-auto justify-between pt-30px items-center" onSubmit={submitChoice}>
            <div className='w-full sm:w-1/4 text-everflow-magenta flex items-center'>
                <input id="PayNowAccountBalanceRadio" defaultChecked onClick={onAccountBalanceSelected} type="radio" name="amount" value='account_balance' />
                <span className="ml-10px text-left"><b>Pay account balance: {PoundCurrency(accountBalance)}</b></span>
            </div>
            <div className='w-full sm:w-1/4 text-everflow-magenta flex items-center'>
                <input id="PayNowCustomAmountRadio" onClick={onCustomAmountSelected} type="radio" name="amount" value='other' className="pr-10px" />
                <span className="ml-10px text-left"><b>Pay other amount</b>{option == 'other-amount' && (<>: <input className='text-everflow-black border-2 border-everflow-magenta w-money-rem moneybox' id="PayNowCustomAmountInput" onFocus={onCustomAmountSelected} type="number" min="5.00" defaultValue={otherAmount} onChange={(e) => { setOtherAmount(e.target.value) }} /></>)}
                    {amountValidationError && (<><br /><span className="text-everflow-red" dangerouslySetInnerHTML={{ __html: amountValidationError }}></span></>)}
                </span>
            </div>
            <div className="w-full sm:w-1/4">
                <InputButton id="PayNowSubmitButton" className="faux-magenta" disabled={submitting} label="Submit" onClick={submitChoice}></InputButton>
            </div>
        </form>
    )
}

export default PaymentAmountChoice