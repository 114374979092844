import React, {useEffect, useState} from "react";
import InputSelect2 from "../../../../Shared/FormComponents/InputSelect2";
import Pagination from "../../../../Shared/Pagination";
import {getTransactions} from "../../../../../lib/api/billing";
import BillsSkeleton from '../Skeleton';
import {ReactComponent as Dl} from '../../../../Shared/svgs/download.svg'
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import DocumentDownloader from "../../../../../lib/api/documentdownloader";
import { PoundCurrency } from '../../../../Shared/EncodingManipulation/index';
import { ErrorLog } from '../../../../Shared/ServerLogging';

const amountPerPage = 10;

const Transactions = ({ helpLink }) => {
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const [page, setPage] = useState(1);
    const [loadError, setLoadError] = useState(false);

    const loadTransactions = () => {
        setLoading(true);
        getTransactions(page, year, amountPerPage)
            .then(response => {
                if (response && response.data && response.data.data) {
                    setTransactions(response.data.data);
                    setLoadError(false);
                } else {
                    setLoadError(true);
                }
            })
            .catch(error => {
                ErrorLog('An error occurred while loading transactions:', error);
                setLoadError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadTransactions();
    }, [page, year]);

    const yearRange = (() => {
        const dates = [];
        for (let i = new Date().getFullYear(); i >= 2021; i--) dates.push(i)
        return dates.map(i => ({label: i, value: i}));
    })()


    return (
        <>
            <div className="flex flex-col sm:flex-row sm:items-center">
                <p className="sm:mr-80px mt-20px sm:mt-0">If you have any questions on your bill. <a href={helpLink || '#'} target="_blank" rel="noopener noreferrer" className="underline text-everflow-magenta font-bold">Check out our Help and FAQs section</a> </p>
                <InputSelect2
                    id="TransactionsYearSelection"
                    className="mt-20px ml-auto sm:mt-0 w-full sm:w-320px"
                    placeholder="Select Year"
                    value={year}
                    options={yearRange}
                    onChange={setYear}
                />
            </div>
            {loading ? (
                <BillsSkeleton
                    rows={transactions && transactions.results && transactions.results.length || amountPerPage}
                    pages={transactions && transactions.maxPage}
                    selected={transactions && transactions.paging && transactions.paging.pageNumber}
                />
            ) : (
                <>
                    {loadError ? (
                        <div className="text-center sm:text-left">
                            There was an error loading this page, please click <span onClick={loadTransactions} className="text-everflow-magenta underline cursor-pointer">here</span> to try again.
                        </div>
                    ) : (
                        <TransactionResults
                            transactions={transactions}
                            setPage={setPage}
                        />
                    )}
                </>
            )}
        </>
    )
}
const TransactionResults = ({transactions, setPage}) => {
    if (!transactions || !transactions.results) return null;

    if (!transactions.results.length) return (
        <div className="text-everflow-magenta text-center">No transaction data to show.</div>
    )

    return (
        <>
            <div className="overflow-x-auto">
                <ul className="min-w-660px text-left divide-y-2 divide-gray-default-500">
                    <li className="h-62px border-b-2 border-everflow-magenta text-12px uppercase flex items-center uppercase font-bold text-center">
                        <div className="w-1/5 pr-20px text-left">Type</div>
                        <div className="w-1/5 pr-20px">Date</div>
                        <div className="w-1/5 pr-20px">Amount</div>
                        <div className="w-1/5 pr-20px">Reference</div>
                        <div className="w-1/5 text-right">Download</div>
                    </li>
                    {transactions.results.map((item, index) => {

                        const strike = item.rebilled || item.removed;

                        return (
                            <li key={`transaction-${index}-${item.transactionReference}`} style={!index ? {borderTop: 0} : {}} className="flex text-center h-56px items-center">
                                <div id={`Transaction${index}Type`} className={`w-1/5 pr-20px text-left ${strike ? ' line-through' : ''}`}>{item.transactionType}</div>
                                <div id={`Transaction${index}EffectiveDate`} className={`w-1/5 pr-20px${strike ? ' line-through' : ''}`}>{moment(item.effectiveDate).format('DD.MM.YYYY')}</div>
                                <div id={`Transaction${index}Amount`} className={`w-1/5 pr-20px${strike ? ' line-through' : ''}`}>{PoundCurrency(item.total)}</div>
                                <div id={`Transaction${index}Reference`} className={`w-1/5 pr-20px${strike ? ' line-through' : ''}`}>{item.transactionReference}</div>
                                <div className="w-1/5 text-right">
                                    <Documents item={item} index={index} />
                                </div>
                            </li>
                        )
                    })}
                </ul>
                <ReactTooltip/>
            </div>
            <div className="text-center mt-80px mb-40px">
                <Pagination
                    pages={transactions.maxPage}
                    selected={transactions.paging.pageNumber}
                    callback={setPage}
                />
            </div>
        </>
    )
}


const Documents = ({item, index}) => {
    const strike = item.rebilled || item.removed;

    const getDocument = async (id) => {
        const downloader = new DocumentDownloader();
        await downloader.downloadDocumentUsingBase64(document, id);
    };

    switch (item.transactionType) {
        case 'Payment':
            return null;
            break;
        case 'Bill':
            return (
                <div className="inline-block">
                    <div className="grid grid-cols-2 gap-10px">
                        {item.pdfId ? (
                            <span id={`Transaction${index}PdfDownload`} data-tip={strike ? 'Transaction no longer active' : ''} onClick={async () => await getDocument(item.pdfId)}
                               className={`inline-flex flex-col items-center cursor-pointer`}>
                                <Dl className="w-16px fill-current"/>
                                <span className="text-10px font-bold">PDF</span>
                            </span>
                        ) : (
                                <span id={`Transaction${index}PdfNotReady`} data-tip="Transaction is not ready to download" className={`cursor-pointer inline-flex flex-col items-center text-grayText-default-300`}>
                                <Dl className="w-16px fill-current"/>
                                <span className="text-10px font-bold">PDF</span>
                            </span>
                        )}
                        {item.csvId ? (
                            <span id={`Transaction${index}CsvDownload`} data-tip={strike ? 'Transaction no longer active' : ''} onClick={async () => await getDocument(item.csvId)}
                               className={`inline-flex flex-col items-center cursor-pointer`}>
                                <Dl className="w-16px fill-current"/>
                                <span className="text-10px font-bold">CSV</span>
                            </span>
                        ) : (
                                <span id={`Transaction${index}CsvNotReady`} data-tip="Transaction is not ready to download" className={`cursor-pointer inline-flex flex-col items-center text-grayText-default-300`}>
                                <Dl className="w-16px fill-current"/>
                                <span className="text-10px font-bold">CSV</span>
                            </span>
                        )}
                    </div>
                </div>
            );
            break;

        default:
            return (
                <div className="inline-block">
                    <div className="grid grid-cols-2 gap-10px">
                        {item.pdfId ? (
                            <span id={`Item${index}PdfDownload`} data-tip={strike ? 'Transaction no longer active' : ''} onClick={async () => await getDocument(item.pdfId)}
                               className={`bill-tooltip-wrapper inline-flex flex-col items-center cursor-pointer ${strike ? 'text-grayText-default-300' : ''}`}>
                                <Dl className="w-16px fill-current"/>
                                <span className="text-10px font-bold">PDF</span>
                            </span>
                        ) : (
                            <span id={`Item${index}PdfNotReady`} data-tip="Transaction is not ready to download" className={`cursor-pointer inline-flex flex-col items-center text-grayText-default-300`}>
                                <Dl className="w-16px fill-current"/>
                                <span className="text-10px font-bold">PDF</span>
                            </span>
                        )}
                    </div>
                </div>
            );
            break;
    }
}


export default Transactions;