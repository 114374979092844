import React, {useState, useEffect, useCallback} from 'react';
import Transactions from './Transactions';
import Bills from './Bills';
import DirectDebitMandate from "../../../Shared/DirectDebitMandate";
import { fetchCustomerDetails } from "../../../../lib/api/customer-details";

const Billing = ({ helpLink }) => {
    const [tab, setTab] = useState('billing');
        const [loadError, setLoadError] = useState(false);
    const [pageData, setPageData] = useState();

    const loadData = useCallback(async () => {
        await fetchCustomerDetails().then((resp) => {
            if (loadError) setLoadError(false);
            setPageData(resp)
        })
            .catch(err => {
                console.log('setting load err', err);
                setLoadError(true);
            })

    }, [setPageData, setLoadError, loadError])

    useEffect(() => {
        loadData().then(r => r);
    }, [loadData]);

    const buttonClasses = "px-5 text-everflow-black bg-everflow-white border-everflow-magenta border-2 mr-10px min-h-50px rounded-2xl font-bold";
    const activeButtonClasses = "px-5 text-everflow-white bg-everflow-magenta border-everflow-magenta border-2 mr-10px min-h-50px rounded-2xl";

    return (
        <div className="my-12 mt-12 sm:mt-24 wrapper">
            <div className="flex flex-col justify-between items-start mb-6">
                {
                    pageData && pageData.accountBalance.paymentMethod !== 'DD' && <DirectDebitMandate/>
                }
                <h2 className="font-bold text-everflow-black text-xl w-full lg:w-1/2 lg:order-first mb-6 lg:mb-0">Your Bills and Transactions</h2>
            </div>

            <div className="flex justify-start">
                <button
                    id="BillingTab"
                    onClick={() => setTab('billing')}
                    className={tab === 'billing' ? activeButtonClasses : buttonClasses}
                >
                    Your Bills
                </button>
                <button
                    id="TransactionsTab"
                    onClick={() => setTab('transactions')}
                    className={tab === 'transactions' ? activeButtonClasses : buttonClasses}
                >
                    Your Transactions
                </button>
            </div>

            {tab === 'transactions' ? <Transactions helpLink={helpLink}/> : null}
            {tab === 'billing' ? <Bills helpLink={helpLink}/> : null}
        </div>
    );
};

export default Billing;
