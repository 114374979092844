import React, { useState } from 'react';
import SearchMeters from './SearchMeters';
import EditMeter from './EditMeter';
import { useRouteMatch } from "react-router-dom";
import { PoundSymbol } from '../../../Shared/EncodingManipulation/index';

const Meters = ({ helpLink }) => {

    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [results, setResults] = useState();

    const match = useRouteMatch('/dashboard/meters/:spid')

    const spid = match && match.params && match.params.spid;

    return (
        <>
            <div className="wrapper mt-60px mb-20px">
                {!spid ? (
                    <>
                        <div className="grid grid-cols-1 sm:grid-cols-9 gap-20px sm:gap-40px mb-10px">
                            <div className="sm:col-span-5">
                                <h2 className="text-everflow-black font-bold mb-20px">Your locations</h2>
                                <p className="text-everflow-black leading-6"><b>The best thing you can do to manage your bills is to send us regular meter readings.</b> If you send us monthly reads, we can include these in your next invoice. We will still send our team out to read your meters twice a year.</p>
                            </div>
                            <div className="sm:col-span-4 bg-everflow-white border-everflow-blue border-2 text-everflow-black p-4 rounded-lg">
                                <h3 className="text-everflow-blue font-bold mb-10px">Win {PoundSymbol()}100 off your bill!</h3>
                                <p className="text-everflow-black leading-6">As well as keeping your bills as accurate as possible, you will be entered into a prize draw, where one lucky winner each month will win {PoundSymbol()}100 off their bills!</p>
                            </div>
                        </div>
                        <SearchMeters
                            search={search}
                            setSearch={setSearch}
                            page={page}
                            setPage={setPage}
                            results={results}
                            setResults={setResults}
                            helpLink={helpLink}
                        />
                    </>
                ) : (
                    <EditMeter
                        spid={spid}
                    />
                )}
            </div>
        </>
    )
}

export default Meters;