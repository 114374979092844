import React from "react";

import { SideSkeleton } from "../../../Shared/Skeletons";

const SkeletonText = ({className, spacing, inheritFontSize, noPulse}) => {
    if(!spacing) spacing = '6px';
    const fontSize = inheritFontSize ? '1em' : '12px';

    return (
        <div style={{height: fontSize, borderRadius: '.5em',  marginBottom: spacing}} className={`bg-gray-default-500 ${noPulse ? '' : ' animate-pulse'}${className ? ' ' + className : ''}`}></div>
    )
}

const SkeletonBlock = ({rows}) => {
    if(!rows) rows = 1;

    const rowBlock = [];
    for(var i = 1; i < rows; i++) rowBlock.push(<SkeletonText />)
    rowBlock.push(<SkeletonText  className="w-1/3" />)
    return (
        <div>
            {rowBlock}
        </div>
    )
}

const SkeletonInput = ({noPulse, className}) => (
    <div className={`h-56px rounded-28px border-2 border-gray-default-500${noPulse ? '' : ' animate-pulse'}${className ? ' ' + className : ''}`}></div>
)

const SkeletonCustomer = () => {

    return (
        <div className="my-50px sm:my-100px wrapper">
            <div className="grid grid-cols-1 sm:grid-cols-12 gap-50px sm:gap-20px">
                <div className="col-span-1 sm:col-span-3">
                    <SideSkeleton />
                </div>
                <div className="col-span-1 sm:col-start-5 sm:col-span-8">
                    <h2 className="text-everflow-magenta mb-40px"><SkeletonText className="w-2/3" /></h2>
                    <div className="grid gap-30px">
                        <SkeletonInput />
                        <SkeletonInput />
                        <SkeletonInput />
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-12 gap-50px sm:gap-20px mt-70px sm:mt-100px">
                <div className="col-span-1 sm:col-span-3">
                    <SideSkeleton />
                </div>
                <div className="col-span-1 sm:col-start-5 sm:col-span-8">
                    <h2 className="text-everflow-magenta mb-40px"><SkeletonText className="w-1/2" /></h2>
                    <div className=" grid gap-30px">
                        <div className="grid grid-cols-2 gap-30px">
                            <SkeletonInput />
                            <SkeletonInput />
                        </div>
                        <SkeletonInput />
                        <h2 className="text-everflow-magenta my-40px"><SkeletonText className="w-2/3"/></h2>
                        <p><SkeletonBlock rows={3} /></p>
                    </div>
                    <div className="sm:col-start-5 sm:col-span-8">
                        <SkeletonInput className="mt-20px w-300px" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkeletonCustomer