import React, { useEffect, useState } from 'react';
import { LiveChatWidget } from "@livechat/widget-react";

function LiveChatLoader() {
    const [isLiveChatEnabled, setIsLiveChatEnabled] = useState(false);
    const [licenseKey, setLicenseKey] = useState(null);

    useEffect(() => {
        fetch('/api/Configuration/live-chat-config')
            .then(response => {
                if (!response.ok) {
                    throw new Error("Failed to fetch live chat configuration");
                }
                return response.json();
            })
            .then(data => {
                setIsLiveChatEnabled(data.enabled);
                setLicenseKey(data.licenseKey);
            })
            .catch(error => {
                console.error("Error loading LiveChat:", error);
            });
    }, []);

    if (!isLiveChatEnabled || !licenseKey) {
        return null;
    }

    return <LiveChatWidget
        license={licenseKey}
        visibility="minimized"/>;
}

export default LiveChatLoader;