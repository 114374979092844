import React, { useState } from 'react';

const PaymentButton = ({ enabled = false, togglePaymentAmountChoice }) => {

    const [paymentButtonOpen, setPaymentButtonOpen] = useState(false);

    return (
        <>
            <button className={`inline-button ${!enabled && 'disabled'}`}
                id='dashboard-payment-nav-btn'
                onClick={() => {
                    if (window?.mixpanel?.track) {
                        window.mixpanel.track("Pay Now Button Clicked");
                    }
                    togglePaymentAmountChoice();
                    setPaymentButtonOpen(!paymentButtonOpen);
                }}
                disabled={!enabled}>
                Pay now
            </button>
            {paymentButtonOpen}
        </>
    )
}

export default PaymentButton