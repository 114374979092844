import React, { useState, useRef, useEffect } from 'react';
import {ReactComponent as LoadingSearchIcon} from '../../../../../Shared/svgs/loading-search-icon.svg'

const SearchBox = ({onChange, searchLoader, value, label, password, className, readOnly, req, error, id, onBlur}) => {

    const [active, setActive] = useState();
    const [length, setLength] = useState();
    const [inc, setInc] = useState();

    const timer = useRef()
    const glassTo = useRef();
    const checkTo = useRef();

    const timerVal = useRef()
    const loaderEl = useRef()

    const showLoader = () => {
        loaderEl.current.style.opacity = 1;
    }

    const hideLoader = () => {
        loaderEl.current.style.opacity = 0;
    }

    const startTimer = () => {

        clearInterval(timer.current);
        clearTimeout(glassTo.current);
        clearTimeout(checkTo.current);

        timerVal.current = length;

        const circle = loaderEl.current.querySelector('circle');
        circle.style.transitionDuration = '0s';
        circle.style.strokeDashoffset = timerVal.current;
        circle.style.strokeOpacity = 1;

        const search = loaderEl.current.querySelector('#loading-search-glass');
        search.style.opacity = 0;

        timer.current = setInterval(()=>{
            timerVal.current -= (inc * 20);

            if(timerVal.current <= 0){
                timerVal.current = 0;
                clearInterval(timer.current);
            }

            const circle = loaderEl.current.querySelector('circle');
            circle.style.transitionDuration = '.5s';
            circle.style.strokeDashoffset = timerVal.current;
        }, 500)
    }

    const endTimer = () => {
        timerVal.current = 0;
        if(!loaderEl.current) return;
        const circle = loaderEl.current.querySelector('circle');
        circle.style.transitionDuration = '.1s';
        circle.style.strokeDashoffset = timerVal.current;
        const search = loaderEl.current.querySelector('#loading-search-check');
        search.style.opacity = 1;
        clearInterval(timer.current);
        checkTo.current = setTimeout(()=> {
            circle.style.transitionDuration = '.5s';
            circle.style.strokeOpacity = '0';
            const check = loaderEl.current.querySelector('#loading-search-check');
            check.style.opacity = 0;
        }, 500)
        glassTo.current = setTimeout(()=> {
            const search = loaderEl.current.querySelector('#loading-search-glass');
            search.style.opacity = 1;
        },1000)
    }

    useEffect(()=>{
        const circle = loaderEl.current.querySelector('circle');
        const initLength = circle.getTotalLength()
        setLength(initLength);
        setInc(initLength / 100)
        circle.style.strokeDasharray = initLength;
        circle.style.transitionProperty= 'all';
        circle.style.transitionTimingFunction = 'linear';
        circle.style.transitionDuration = '0';
        circle.style.strokeOpacity = 0;

        const tick = loaderEl.current.querySelector('#loading-search-check');
        tick.style.opacity = 0;
        tick.style.transitionProperty= 'all';
        tick.style.transitionTimingFunction = 'linear';
        tick.style.transitionDuration = '.5s';

        const search = loaderEl.current.querySelector('#loading-search-glass');
        search.style.transitionProperty= 'all';
        search.style.transitionTimingFunction = 'linear';
        search.style.transitionDuration = '.5s';

        return () => {
            clearInterval(timer.current)
            clearTimeout(glassTo.current);
            clearTimeout(checkTo.current);
        }
    },[])

    if(searchLoader) searchLoader.current = {
        showLoader,
        hideLoader,
        startTimer,
        endTimer
    }

    const focus = active || !!value;

    const classes = [
        'input-text',
        'grid',
        'grid-cols-1',
        'md:grid-cols-9'
    ]

    if(className) classes.push(className);
    if(focus) classes.push('focus');

    return (
        <div className={classes.join(' ')}>
            <div className="w-full col-span-5 input-text-wrapper">
                {label ? (
                    <label>{label}{req ? (<span className="text-everflow-magenta"> *</span>) : null}</label>
                ) : null}
                <div ref={loaderEl} className="transition-all absolute top-1/2 right-9px transform -translate-y-1/2 flex">
                    <LoadingSearchIcon className="w-42px" />
                </div>
                <input
                    id={id}
                    onChange={onChange}
                    value={value}
                    type={password ? 'password' : 'text'}
                    onFocus={()=>setActive(true)}
                    onBlur={ev=>{setActive(false); onBlur && onBlur(ev)}}
                    readOnly={!!readOnly}
                    className="rounded-lg"
                />
            </div>
            {error ? (
                <div className="px-28px py-10px text-everflow-magenta text-13px">{error}</div>
            ) : null}
        </div>
    )
}

export default SearchBox