import React from 'react';
const DirectDebitMandate = () => {

    return (
        <div className="mt-40px mb-20px w-full bg-everflow-white border-everflow-blue border-2 text-everflow-black p-4 rounded-lg">
            <h3 className="font-bold mb-2 text-lg text-everflow-black">You are currently on our manual payment method. Why not move to Direct Debit?</h3>
            <p className="text-md text-everflow-black">Uh oh! You're not on Direct Debit, which means you could miss payments and be charged!
                Please click the button below to download our Direct Debit mandate and email the completed form to customer.services@everflowutilities.com</p>
            <a
                href="/assets/C01DDM - DDM Direct Debit Mandate.pdf"
                target="_blank" rel="noopener noreferrer"
                onClick={() => { if (window?.mixpanel?.track) { window.mixpanel.track("Direct Debit Mandate Viewed") } }}
                className="sm:w-1/3 mt-20px h-50px bg-everflow-blue text-everflow-black font-bold rounded-25px flex justify-center items-center ml-auto"
            >
                Download Direct Debit form
            </a>
        </div>
    )
}

export default DirectDebitMandate