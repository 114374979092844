import React from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as Logo} from '../svgs/logo.svg'

const LoginWrapper = ({noLink, children}) => (
    <div
        className="flex flex-col min-h-screen bg-everflow-magenta">
        <div className="text-center p-50px"><div style={{ width: 300, margin: 'auto' }}>{noLink ? <Logo /> : <Link to="/"><Logo /></Link>}</div></div>
        <div className="flex-grow flex flex-col justify-center items-center p-20px relative">
            {children}
        </div>
        <div className="py-20 items-center text-white sm:p-20 text-center">
            <div>
                <a href="https://www.everflowwater.com/terms" target="_blank" rel="noreferrer">Terms & Conditions</a> | <a href="https://www.everflowwater.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
            </div>
            <div className="mt-20px sm:mt-0">
                © Everflow 2023
            </div>
        </div>
    </div>
)

export default LoginWrapper;