import React, { useState } from 'react';
import InputButton from '../../../Shared/FormComponents/InputButton';
import InputText from '../../../Shared/FormComponents/InputText';
import { forgotPassword } from '../../../../lib/api/user';
import {stripUsernameChars} from '../../../Shared/StringManipulation';
import Form from '../../../Shared/Form';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

const LoginBox = () => {    

    const [loading, setLoading] = useState();
    const [success, setSuccess] = useState();
    const history = useHistory()
    
    const resetPass = ({account}, {setStatus, setErrors}) => {
        console.log('running resetPass function for account:' + account);
        setLoading(true);
        forgotPassword(account.trim())
            .then(resp => {
                console.log('success', resp);
                setLoading(false);
                setSuccess(true);
                setTimeout(() => {
                    history.push('/login/forgot/check-email');
                }, 1000)

            })
            .catch(err => {
                console.log('err', err)
                setLoading(false);
                setErrors({ account: 'Unable to issue password reset.' });
            })
    }

    return (
        <div className="w-full max-w-450px m-auto">
            <div className="bg-white w-full rounded-2xl">
                <div className="p-40px sm:p-60px">
                    <h3 className="uppercase text-18px sm:text-16px text-everflow-magenta font-bold text-center">Forgotten your password?</h3>
                    <p className="mt-32px text-everflow-black text-center">No worries! Simply submit your account number to reset it</p>
                <Form
                    initialValues={{account: ''}}
                    validationSchema={forgotSchema}
                    onSubmit={resetPass}
                    className="mt-32px grid grid-cols-1 gap-y-24px justify-items-center"
                >
                    {({values, handleChange, setFieldValue,  errors, touched, handleBlur, handleSubmit}) => {
                        return(
                            <>
                                <InputText
                                    label="Account number"
                                    placeholder="e.g. EFW000000-0"
                                    className="w-full rounded-28px"
                                    id="account"
                                    req
                                    value={values.account}
                                    error={errors.account}
                                    onChange={ev => stripUsernameChars(ev.target.value, val => setFieldValue('account', val))}
                                    onBlur={handleBlur}
                                    tick={success}
                                />
                                <InputButton
                                    loading={loading}
                                    className="w-full font-bold"
                                    label="Request password reset"
                                    onClick={handleSubmit}
                                />
                            </>
                        )     
                    }}

                </Form>

                </div>
                <div className="p-32px text-center border-t-2 border-gray-default-500">
                    <div>
                        <Link className="underline text-everflow-black" to="/">Return to Login</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const forgotSchema = Yup.object().shape({
    account: Yup.string().required('Please enter your account number')
})

export default LoginBox