import React, { useEffect } from 'react'
import LoginWrapper from '../../Shared/LoginWrapper';
import CheckContactEmail from '../CheckContactEmail';
import moment from 'moment-timezone';
import { checkMaintenance } from '../../../lib/maintenance/es6';
import { useHistory } from 'react-router-dom';

const [start, end] = process.env.REACT_APP_MAINTENANCE.split('-').map(i => {
    var time = i.split(':');
    if(time.length === 1) time.push(0)
    const utc = `${moment().format('YYYY-MM-DD')} ${time[0]}:${time[1]}`
    return moment.utc(utc).tz('Europe/London').format('h:mma');
});


const Maintenance = () => {
    const history = useHistory()

    useEffect(()=>{
        if(!checkMaintenance()) history.push('/');
    }, [])

    return (
        <LoginWrapper noLink>
            <div className="z-10 w-full">
                <CheckContactEmail
                    title="Under maintenance"
                >
                    The Customer Portal is under maintenance between the hours of {start} and {end}.
                </CheckContactEmail>
            </div>
        </LoginWrapper>
    )
}

export default Maintenance;