import React, { useEffect, useState } from 'react';
import DashboardWrapper from '../../Shared/DashboardWrapper';
import { useLogin } from '../../../lib/hooks/useLogin';
import { useHistory, useLocation } from "react-router";
import useConfig from '../../../lib/hooks/useConfig';
import { InformationLog } from '../../Shared/ServerLogging';

const CardPayment = () =>
{
    const unauthorizedReturnUrl = "/dashboard";
    const loggedIn = useLogin();
    const { state } = useLocation();
    const history = useHistory();
    const ccConfig = useConfig('card-payment-config');
    const [source, setSource] = useState("/404");

    useEffect(() =>
    {
        if ((loggedIn && !loggedIn.loading) && (!loggedIn.account.token))
        {
            InformationLog("Unauthenticated User. Redirecting to Login Page");
            history.push('/login');
        }
    }, [loggedIn, history])

    useEffect(() => {
        window.top.scrollTo(0, 0)
    }, [])

    useEffect(() =>
    {
        if (typeof state !== 'undefined')
        {
            InformationLog(`State Information: ${JSON.stringify(state)}`);

            if (typeof ccConfig !== 'undefined')
            {
                InformationLog(`Configuration Information: ${JSON.stringify(ccConfig)}`);

                if (!ccConfig?.cardPaymentEnabled)
                {
                    InformationLog(`Card Payment Functionality Disabled. Redirecting to ${unauthorizedReturnUrl}.`);
                    history.push(unauthorizedReturnUrl);
                }
                else if (!ValidateAccessSource(state.from))
                {
                    InformationLog(`Unauthorized Page Access Request. Unauthorized Access Request Origin, ${state.from}. Redirecting to ${unauthorizedReturnUrl}.`);
                    history.push(unauthorizedReturnUrl);
                }
                else if (!ValidatePaymentUrl(state.targetUrl, ccConfig?.cardPaymentUrl))
                {
                    InformationLog(`Required Url Information is missing or invalid. Redirecting to ${unauthorizedReturnUrl}.`);
                    history.push(unauthorizedReturnUrl);
                }
                else
                {
                    setSource(state.targetUrl);
                }
            }
        }
        else
        {
            InformationLog(`Unauthorized Page Access Request. Required State Information Undefined. Redirecting to ${unauthorizedReturnUrl}.`);
            history.push(unauthorizedReturnUrl);
        }

    }, [ccConfig, state]);

    return (
        <>{
            <DashboardWrapper>
                <div id="cardpayment-wrapper" className="wrapper pt-20px pb-20px">
                    <div className="flex flex-col w-100  mb-20px mx-auto p-4 border-4 border-red-600 text-center">
                        <p className="pb-10px">Please <b>do not</b> refresh this page during your transaction and be sure to click the payment button just once.</p>
                    </div>
                    <div className="flex flex-col">
                        <iframe title="Pay360 Hosting Iframe" className='w-full h-1200 overflow-y-hidden' id="iframe-pay360" src={source}></iframe>
                    </div>
                </div>
            </DashboardWrapper>
        }</>
    )
}

function ValidateAccessSource(source)
{
    if (typeof source == 'undefined' && source == null && source.trim() === "")
    {
        InformationLog(`Unauthorized Access Source, ${source}.`);
        return false;
    }

    const acceptedSources = ["/dashboard/home"];
    const iterator = acceptedSources.values();

    for (const value of iterator)
    {
        if (source === value)
        {
            InformationLog(`Authorized Access Source, ${source}.`);
            return true;
        }
    }

    return false;
}

function ValidatePaymentUrl(testUrl, validationUrl)
{
    InformationLog(`Card Payment Url Target: ${testUrl}`);

    if (typeof testUrl !== 'undefined' && testUrl !== null && testUrl.trim() !== "")
    {
        const regex = new RegExp(`^(${validationUrl})`);

        return regex.test(testUrl);
    }

    InformationLog(`Invalid Card Payment Url`);
    return false;
}

export default CardPayment