import React from 'react';
import { Formik } from "formik";

const Form = ({initialValues, validationSchema, onSubmit, children, className}) => {
    console.log('validating schema', validationSchema)

    if(!initialValues) initialValues = {};
    if(!onSubmit) onSubmit = () => console.log('onSubmit not set');

    return(
        <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({values, setFieldValue, handleSubmit, errors, status, validateField, handleChange, handleBlur, touched}) => {                

                /*

                const handleChange2 = e => {
                    setFieldValue(e.target.id, e.target.value);
                    try{
                        if(e.target.value){
//                            console.log('validating val', e.target.value)
                            validateField(e.target.id)
                        }
                    }catch(err){
//                        console.log(err);
                    }
                };
*/
                

                return(
                    <form className={className}>
                        {status ? (
                            <div className="text-everflow-magenta" dangerouslySetInnerHTML={{ __html: status }}></div>
                        ) : null}
                        {children({values, setFieldValue, errors, handleChange, handleBlur, handleSubmit, touched})}
                    </form>
                )
            }}
        </Formik>
    )
}

export default Form;