import React, { useRef, useState } from 'react';
import InputText from '../../../../../Shared/FormComponents/InputText'
import InputButton from '../../../../../Shared/FormComponents/InputButton';
import { ReactComponent as DropDown } from '../../../../../Shared/svgs/dropdown.svg'
import { ReactComponent as BlackInverseTick } from '../../../../../Shared/svgs/tick-ef-black.svg'
import moment from 'moment';
import { Formik } from 'formik'
import * as Yup from 'yup';
import { submitReading, validateReading } from '../../../../../../lib/api/premises';
import DatePicker from '../../../../../Shared/FormComponents/DatePicker';

const ModalBackground = () => {
    return <div id="ModalBackground" className='bg-everflow-black' style={{ position: 'fixed', zIndex: 25, top: 0, left: 0, width: '1000vw', height: '1000vh', opacity: '0.5' }}></div>
}

const ConfirmModal = ({ date, reading, serial, doReading, setShowConfirmModal, setSubmitting, submitting }) => {
    if (!date || !reading || !serial) {
        return null
    }

    const ukDate = moment(date, "DD.MM.YYYY").format("DD/MM/YYYY")
    const text = `You have submitted a read of ${Number(reading)}m3 for ${ukDate} for meter: ${serial}. Is this correct?`

    return (
        <>
            <ModalBackground></ModalBackground>
            <div className='modal'>
                <p className='mb-4 text-everflow-black'>{text}</p>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                    <button id="MeterModalCancelButton" className='button pink faux' disabled={submitting == "validating"} onClick={() => { setSubmitting(); setShowConfirmModal(false); }}>No</button>
                    <button id="MeterModalConfirmButton" className='button pink' disabled={submitting == "validating"} onClick={() => { setSubmitting("validating"); doReading() }}>Yes</button>
                </div>
            </div>
        </>
    )
}

const SoftValidationModal = ({ date, reading, serial, doReading, setShowSoftValidateModal, errors, setSubmitting, submitting }) => {
    if (!date || !reading || !serial || !errors) {
        return null
    }

    const ukDate = moment(date, "DD.MM.YYYY").format("DD/MM/YYYY")
    const text = `You have submitted a read of ${Number(reading)}m3 for ${ukDate} for meter: ${serial}. This read is not in line with what we were expecting.`

    return (
        <>
            <ModalBackground></ModalBackground>
            <div className='modal large text-everflow-black'>
                <p className='mb-4 text-everflow-black'>{text}</p>
                {errors.length > 0 && (
                    <ul className="my-4 list-disc text-everflow-black">
                        {errors.map(err => <li className="ml-4" key={err.id}>{err.description}</li>)}
                    </ul>
                )}
                <p className='my-4'>Here are some things to check before confirming the read:</p>
                <ul className='list-disc'>
                    <li className='ml-4'>Make sure the meter serial number is correct.</li>
                    <li className='ml-4'>Make sure you have only entered the correct numbers. These may be in black and white only for an analogue meter, or on a digital meter this could be the numbers before the comma.</li>
                    <li className='ml-4'>Your meter may have rolled over (gone from 9999 then to 0).</li>
                </ul>
                <p className='my-4'>If you have completed the above checks and the reading is right, please confirm the read.</p>
                
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                    <button id="MeterFailedValidationCancelButton" disabled={submitting == "validating"} className='button pink faux' onClick={() => { setSubmitting(); setShowSoftValidateModal(false); }}>My read is incorrect and I'd like to re-enter it</button>
                    <button id="MeterFailedValidationConfirmButton" disabled={submitting == "validating"} className='button pink' onClick={() => { doReading(reading, date) }}>My read is correct and I'd like to submit it</button>
                </div>
            </div>
        </>
    )
}

const MeterEntry = ({ item, expanded, setExpanded }) => {
    const [loading, setLoading] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showSoftValidateModal, setShowSoftValidateModal] = useState(false);
    const [error, setError] = useState()
    const [failureReasons, setFailureReasons] = useState()
    const [readingObject, setReadingObject] = useState()
    const [expand] = useState(0);
    const ref = useRef();

    const display = expanded ? 'flex' : 'none';

    const showElementById = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.classList.remove('hidden');
        }
    }

    const hideElementById = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.classList.add('hidden');
        }
    }

    const confirmReading = () => {
        setShowConfirmModal(true);
    }

    const softFailReading = () => {
        setShowSoftValidateModal(true);
    }

    const processReading = ({ reading, date }) => {
        hideElementById('yourmeters-submitreading-success-' + item.meterSerial);
        setSubmitting("validating")

        validateReading({
            spidMeterId: item.spidMeterId,
            meterSerial: item.meterSerial,
            reading,
            date
        }).then(res => {
            const firstError = res.data.data.failureReasons[0]
            setFailureReasons(res.data.data.failureReasons)
            setSubmitting("modal")

            if (firstError.description === "Success") {
                setReadingObject({
                    validationPassed: true,
                    generalSpid: item.generalSpid,
                    reading: parseInt(reading),
                    date,
                    spidMeterId: item.spidMeterId,
                    meterSerial: item.meterSerial,
                    failureReasons: res.data.data.failureReasons.map(i => i.id)
                })
                confirmReading()
            } else {
                setReadingObject({
                    validationPassed: false,
                    generalSpid: item.generalSpid,
                    reading: parseInt(reading),
                    date,
                    spidMeterId: item.spidMeterId,
                    meterSerial: item.meterSerial,
                    failureReasons: res.data.data.failureReasons.map(i => i.id)
                })
                softFailReading()
            }
        })
    }

    const doReading = async () => {
        setLoading(true);
        setShowSoftValidateModal(false)
        setShowConfirmModal(false)

        await submitReading(readingObject)
            .then(resp => {
                if (error) setError(false);
                if (window?.mixpanel?.track) {
                    window.mixpanel.track("Meter Reading Submitted");
                }
                setLoading(false);
                showElementById('yourmeters-submitreading-success-' + item.meterSerial);
            })
            .catch(err => {
                console.log('submitreading err', err)
                setLoading(false)
                setError(err && err.response && err.response.data || "Unexpected error, please try again later.")
            });
    }

    return (
        <li className="border-b-2 border-everflow-black">
            <div>
                <div className="py-10px text-everflow-black flex flex-col items-start md:flex-row md:justify-center md:items-center gap-10px">
                    <div className="flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                        <div className="md:hidden w-100px">Meter ID</div>
                        <div id={`Meter-${item.meterSerial}-ID`} className="break-all font-bold">{item.meterSerial}</div>
                    </div>
                    <div className="flex flex-row mb-4 md:mb-0 md:flex-grow">
                        <div className="md:hidden w-100px flex-shrink-0">Meter Location</div>
                        <div id={`Meter-${item.meterSerial}-Location`} className="break-all pr-10px flex-shrink font-bold">{item.meterLocationDescription}</div>
                    </div>
                    <div className="flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                        <div className="md:hidden w-100px">Last Read Date</div>
                        <div id={`Meter-${item.meterSerial}-LastReadDate`} className="font-bold">{moment(item.meterReadDate).format('DD.MM.YYYY')}</div>
                    </div>
                    <div className="flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                        <div className="md:hidden w-100px">Last Read Value</div>
                        <div id={`Meter-${item.meterSerial}-LastReadValue`} className="font-bold">{item.meterReadValue}</div>
                    </div>

                    <div className="mb-4 md:mb-0 w-220px flex-shrink-0">
                        {item.meterReadType !== "F" ?
                            (<button 
                                id={`yourmeters-enterreading-drp-${item.meterSerial}`}
                                className="max-w-full h-50px w-full font-bold box-border border-2 border-white rounded-25px flex justify-center items-center bg-everflow-magenta text-white"
                                onClick={() => setExpanded(expanded ? false : item.spidMeterId)}>
                                Enter meter reading
                                <DropDown
                                    style={!!expand ? { transform: 'scaleY(-1)' } : {}}
                                    className="block text-black fill-current w-12px flex items-center ml-10px text-white" />
                            </button>) : (<p>Reads cannot be added to inactive meters</p>)}
                    </div>
                </div>
            </div>

            <Formik
                initialValues={{ reading: '', date: '' }}
                validationSchema={ReadingSchema}
                onSubmit={processReading}
                onTouchCallback
            >
                {({ values, handleChange, touched, errors, handleBlur, handleSubmit }) => {

                    const resetTick = ev => {
                        if (success) setSuccess(false);
                        if (error) setError(false);
                        handleChange(ev);
                    }

                    return (
                        <>
                            {showConfirmModal && <ConfirmModal reading={values.reading} date={values.date} serial={item.meterSerial} doReading={doReading} setShowConfirmModal={setShowConfirmModal} setSubmitting={setSubmitting} submitting={submitting}></ConfirmModal>}
                            {showSoftValidateModal && <SoftValidationModal reading={values.reading} date={values.date} serial={item.meterSerial} setShowSoftValidateModal={setShowSoftValidateModal} doReading={doReading} errors={failureReasons} submitting={submitting} setSubmitting={setSubmitting}></SoftValidationModal>}
                        <div className="overflow-hidden relative transition-all border-2 border-everflow-grey-25 rounded-18px flex-col mb-2 p-8" style={{ display }}>
                            <div className="mb-2 text-everflow-black">
                                <p className="font-bold text-xl">Enter your reading</p>
                                <p>Need help with your meter reading? <a href="https://everflowutilities.com/help-support/water-help-centre" className="text-everflow-magenta underline font-bold">View our guide</a></p>
                            </div>
                            <div ref={ref} className="py-10px">
                                <form className="flex flex-col md:flex-row">
                                    <div className="w-220px mr-20px mb-4 md:mb-0">
                                            <InputText
                                                thin
                                                id={`yourmeters-meterreading-txt-${item.meterSerial}`}
                                                name="reading"
                                                label="Meter reading"
                                                labelFor={`yourmeters-meterreading-txt-${item.meterSerial}`}
                                                value={values.reading}
                                                onChange={resetTick}
                                                error={touched.reading && errors.reading}
                                                onBlur={handleBlur}
                                                tick={success}
                                                className={`rounded-xl`}
                                                maxLength={9}
                                         />
                                    </div>
                                    <div className="w-220px mr-20px mb-4 md:mb-0">
                                        <DatePicker
                                            thin
                                            id={`yourmeters-dateofreading-dte-${item.meterSerial}`}
                                            name="date"
                                            label="Date of reading"
                                            labelFor={`yourmeters-dateofreading-dte-${item.meterSerial}`}
                                            value={values.date}
                                            onChange={(val) => { resetTick({ target: { id: 'date', value: val } }) }}
                                            format={'DD.MM.YYYY'}
                                            error={touched.date && errors.date}
                                            onBlur={handleBlur}
                                            tick={success}
                                            className={`rounded-xl`}
                                        />
                                    </div>
                                    <div className="w-180px mb-4 md:mb-0">
                                        <InputButton
                                            id={`yourmeters-submitreading-btn-${item.meterSerial}`}
                                            className="max-w-full"
                                            label="Submit reading"
                                            onClick={handleSubmit}
                                            loading={loading}
                                            disabled={submitting == "validating"}
                                            error={error}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                        </>
                    )
                }}
            </Formik>

            <div id={`yourmeters-submitreading-success-${item.meterSerial}`} className="p-8 bg-everflow-green rounded-18px text-everflow-black flex items-center justify-around hidden gap-4 mb-4">
                <BlackInverseTick className="w-56px"></BlackInverseTick>
                <p className="text-2xl font-bold">Thank you, your meter reading has been added</p>
            </div>
        </li>
    )
}

const parseDate = (dateVal, original) => {
    if (original && !original.match(/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/)) return null;
    return moment(original, "DD.MM.YYYY").toDate();
}

const ReadingSchema = Yup.object().shape({
    reading: Yup.string().typeError('Invalid reading').matches(/^[0-9]+$/, 'Invalid reading').required('Reading is required'),
    date: Yup.date().max(new Date(), "Date cannot be in the future").typeError('Expected DD.MM.YYYY').transform(parseDate).required('Date is required')
})

export default MeterEntry;
