import React, { useCallback, useState } from 'react';
import InputButton from '../../../Shared/FormComponents/InputButton';
import InputText from '../../../Shared/FormComponents/InputText';
import {ReactComponent as Cross} from '../../../Shared/svgs/cross.svg'
import {ReactComponent as Tick} from '../../../Shared/svgs/tick.svg'
import {ReactComponent as Dash} from '../../../Shared/svgs/dash-pass.svg'
import { useLocation, useHistory } from "react-router-dom";
import queryString from 'query-string';
import { confirmAccount } from '../../../../lib/api/user';



const CreatePass = () => {

    const location = useLocation()
    const history = useHistory();


    const [password, setPassword] = useState();
    const [password2, setPassword2] = useState();
    const [passTouch, setPassTouch] = useState(false);
    const [pass2Touch, setPass2Touch] = useState(false);
    const [formStatus, setFormStatus] = useState();
    const [loading, setLoading] = useState();

    const searchParams = location.search ? (function(){
        // compensate for base64 being url encoded, and expected back as url encoded
        // all broswer functions to get query params url decode by default

        var temp = location.search.indexOf('?') == 0 ? location.search.substring(1) : location.search;
        temp = temp.split('&');
        const data = {};
        temp.map(i => {
            if(i.indexOf('accountNumber') == 0) data.accountNumber = i.replace('accountNumber=', '');
            if(i.indexOf('confirmToken') == 0) data.confirmToken = i.replace('confirmToken=', '');
        })

        return data;

    })() : {};

    const {accountNumber, confirmToken} = searchParams;

    console.log('confirm password params', searchParams)

    const check = (() => {

        const pass = password ? password : ''
        const checks = {
            minLength: pass.length >= 8,
            digit: !!pass.match(/\d+/),
            upper: !!pass.match(/[A-Z]+/),
            lower: !!pass.match(/[a-z]+/),
            special: !!pass.match(/[!"£$%^&*\(\)_\-=+'@\#]+/)
        };

        checks.all = !Object.keys(checks).find(i => !checks[i])

        return checks;
    })()

    const doConfirm = () => {
        setPassTouch(true)
        setPass2Touch(true)

        if(!check.all) return;
        const data = {
            password: password,
            accountNumber,
            confirmToken
        }

        if(!password || !password2 || password !== password2) return;

        if(!accountNumber || !confirmToken) return setFormStatus('Invalid confirmation link.');

        setLoading(true);

        confirmAccount(data)
            .then(resp => {
                setLoading(false)
                setFormStatus('Account Setup Complete')
                setTimeout(()=>{
                    history.push('/login');
                }, 2000)
            })
            .catch(err => {
                setLoading(false)
                setFormStatus(err.message)
            })
    }
    
    return (
        <div className="w-full max-w-450px m-auto">
            <div className="bg-white w-full rounded-2xl">
                <div className="p-40px sm:p-60px">
                    <h3 className="uppercase text-everflow-magenta font-bold text-center">Create your password</h3>
                    
                    <form className="grid grid-cols-1 gap-y-24px justify-items-center mt-32px">
                        {formStatus ? (
                            <div className="text-everflow-magenta">{formStatus}</div>
                        ) : null}
                        <InputText
                            className="w-full rounded-28px"
                            label="Password"
                            req
                            password
                            value={password}
                            onChange={ev => setPassword(ev.target.value)}
                            tick={check.all}
                            error={passTouch && !check.all ? 'Please check password requirements' : false}
                            onBlur={()=>setPassTouch(true)}
                        />
                         <InputText
                            label="Retype password"
                            req
                            password
                            className="w-full rounded-28px"
                            value={password2}
                            error={pass2Touch && password !== password2 ? "Passwords don't match" : false}
                            onChange={ev => setPassword2(ev.target.value)}
                            tick={password2 && password2 == password}
                            onBlur={()=>setPass2Touch(true)}
                        />
                        <div>
                            <div className="text-left w-full">Password requirements:</div>
                            <ul className="password-checklist">
                                <li><Icon dash={!passTouch} tick={check.minLength} /> Minimum length of 8 characters</li>
                                <li><Icon dash={!passTouch} tick={check.digit} />  Contains a digit</li>
                                <li><Icon dash={!passTouch} tick={check.upper} />  Contains an uppercase character</li>
                                <li><Icon dash={!passTouch} tick={check.lower} />  Contains a lowercase character</li>
                                <li><Icon dash={!passTouch} tick={check.special} /> Contains a special character</li>

                            </ul>
                        </div>
                        <InputButton
                            className="w-full"
                            label="Create password"
                            onClick={doConfirm}
                            loading={loading}
                        />
                    </form>

                </div>
            </div>
        </div>
    )
}

const TickWrapper = () => {
    return (
        <span className="tick">
            <Tick />
        </span>
    )
}

const CrossWrapper = () => {
    return (
        <span className="cross">
            <Cross />
        </span>
    )
}

const DashWrapper = () => {
    return (
        <span className="dash">
            <Dash />
        </span>
    )
}

const Icon = ({tick, dash}) => {
    if(dash && !tick) return <DashWrapper />
    if(tick) return <TickWrapper />
    return <CrossWrapper />
}

export default CreatePass