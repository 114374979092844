import React, { useState, useEffect } from 'react'
import { getCrossSellMessage } from '../../lib/api/cross-sell'

const CrossSellBanner = ({ dismissed, setDismissed }) => {
    const [message, setMessage] = useState()
    const html = window.document.getElementsByTagName("html")[0]

    const onDismiss = () => {
        html.classList.remove('overflow-y-hidden')
        window.sessionStorage.setItem('CrossSellBannerDismissed', true)
        setDismissed(true)
    }

    useEffect(() => {
        const dismissedSession = window.sessionStorage.getItem('CrossSellBannerDismissed')
        if (dismissedSession) {
            onDismiss()
        } else {
            try {
                getCrossSellMessage().then((res) => {
                    const message = res.data.message

                    if (message && message !== null && message !== "null") {
                        html.classList.add('overflow-y-hidden')
                        setMessage({ __html: message })
                    } else {
                        onDismiss()
                    }
                })
            } catch (err) {
                console.log(err)
                onDismiss()
            }
            
        }
    }, [])

    if (dismissed || (!message || message == null || message == "null")) {
        return null
    }

    return (
        <>
            <div id="CrossSellBackground" className='bg-everflow-black' style={{ position: 'fixed', zIndex: 10, top: 0, left: 0, width: '100vw', height: '100vh', opacity: '0.5' }}></div>
            <div
                className="mx-auto fixed w-11/12 sm:w-400px p-4 border-everflow-magenta border-2 rounded-xl bg-white"
                id="CrossSellBanner"
            >
                <span id="CrossSellBannerCloseButton" style={{ top: '0.5rem', right: '0.5rem' }} className='cursor-pointer absolute' onClick={onDismiss}><img width='24' height='24' src='/assets/icons/close002.svg' /></span>
                <h1 className="text-xl font-bold mb-2">Important message</h1>
                <div id="CrossSellBannerMessage" dangerouslySetInnerHTML={message}></div>
            </div>
        </>
    )
}

export default CrossSellBanner
