import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";

import { useLogin } from '../../../lib/hooks/useLogin';
import DashboardWrapper from '../../Shared/DashboardWrapper';
import CrossSellBanner from '../../Shared/CrossSellBanner';

import DashboardContent from './DashboardContent';
import DashboardHome from './Home';
import Meters from './Meters';
import Billing from './Billing';
import Customer from './Customer';
import { getExternalLink } from '../../../lib/api/external-link';


const Dashboard = ({ account }) => {
    const loggedIn = useLogin();
    const history = useHistory()
    const [dismissed, setDismissed] = useState()
    const [helpLink, sethelpLink] = useState('');
    
    useEffect(() => {
        if ((loggedIn && !loggedIn.loading) && (!loggedIn.account.token)) history.push('/login');

        getExternalLink().then((resp) => {
            sethelpLink(resp.data.faqLink);
        })

    }, [loggedIn, history])

    return (
        <DashboardWrapper helpLink={helpLink} >
            <CrossSellBanner dismissed={dismissed} setDismissed={setDismissed}></CrossSellBanner>
            <DashboardContent helpLink={helpLink} account={account}>
                {({ tab, helpLink }) => {
                    switch (tab) {
                        case 'home':
                            return <DashboardHome />
                        case 'meters':
                            return <Meters helpLink={helpLink} />
                        case 'billing':
                            return <Billing helpLink={helpLink} />
                        case 'customer':
                            return <Customer />
                        default:
                            return null
                    }
                }}
            </DashboardContent>
        </DashboardWrapper>
    )
}

export default Dashboard