import React from 'react';
import {SkeletonPagination, SkeletonText} from '../../../Shared/Skeletons';

const BillsSkeleton = ({rows, selected, pages}) => {

    console.log('skeleton overview pages', pages)
    if(!rows) rows = 5;
    return (
        <div className=" animate-pulse">
            <ul className="overflow-auto min-w-660px text-left divide-y-2 divide-gray-default-500">
                <li className="h-62px border-b-2 border-gray-default-500 text-12px uppercase flex items-center uppercase font-bold text-center">
                    <div className="w-1/5 pr-20px text-left"><SkeletonText className="w-1/2"/></div>
                    <div className="w-1/5 pr-20px"><SkeletonText className="w-1/3"/></div>
                    <div className="w-1/5 pr-20px"><SkeletonText className="w-1/2"/></div>
                    <div className="w-1/5 pr-20px"><SkeletonText className="w-3/4"/></div>
                    <div className="w-1/5 text-right"><SkeletonText className="w-1/2 inline-block" /></div>
                </li>
                {Array.from({length: rows}, (v, i) => i).map((item, index) => {
                    if(item % 2 == 0) return (
                        <li key={`skeleton-${index}`} style={!index ? {borderTop: 0} : {}} className="flex text-center h-56px items-center">
                            <div className="w-1/5 pr-20px text-left"><SkeletonText  className="w-1/3"/></div>
                            <div className="w-1/5 pr-20px"><SkeletonText className="w-1/2"/></div>
                            <div className="w-1/5 pr-20px"><SkeletonText className="w-3/4"/></div>
                            <div className="w-1/5 pr-20px"><SkeletonText className="w-1/2"/></div>
                            <div className="w-1/5 text-right"><SkeletonText className="w-1/3 inline-block"/></div>
                        </li>
                    )



                    return (
                            <li key={`skeleton-${index}`} style={!index ? {borderTop: 0} : {}} className="flex text-center h-56px items-center">
                                <div className="w-1/5 pr-20px text-left"><SkeletonText  className="w-1/2"/></div>
                                <div className="w-1/5 pr-20px"><SkeletonText className="w-3/4"/></div>
                                <div className="w-1/5 pr-20px"><SkeletonText className="w-1/2"/></div>
                                <div className="w-1/5 pr-20px"><SkeletonText className="w-2/3"/></div>
                                <div className="w-1/5 text-right"><SkeletonText className="w-1/2 inline-block"/></div>
                            </li>
                    )
                })}
            </ul>
            <div className="text-center mt-80px">
                <SkeletonPagination
                    pages={pages}
                    selected={selected}
                />
            </div>
        </div>
    )
}

export default BillsSkeleton