function Log(path, content)
{
    const controller = new AbortController();
    const signal = controller.signal;

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ logContent: content })
    };

    const sendData = async (path, requestOptions, signal) =>
    {
        await fetch(path, requestOptions, { signal: signal })
            .then(response =>
            {
                if (!response.ok)
                {
                    throw new Error("Failed to generate log");
                }
            });
    }

    sendData(path, requestOptions, signal)
        .catch(error =>
        {
            if (error.name === 'AbortError')
            {
                WarningLog('Aborted Action: Generating Log');
            }
            else
            {
                WarningLog("Error Generating Log:", error);
            }
        });

    return;
}

export function VerboseLog(content)
{
    return Log(`/api/ServerLogging/log-verbose`, content);
};

export function DebugLog(content)
{
    return Log(`/api/ServerLogging/log-debug`, content);
};

export function InformationLog(content)
{
    return Log(`/api/ServerLogging/log-information`, content);
};

export function WarningLog(content)
{
    return Log(`/api/ServerLogging/log-warning`, content);
};

export function ErrorLog(content)
{
    return Log(`/api/ServerLogging/log-error`, content);
};

export function FatalLog(content)
{
    return Log(`/api/ServerLogging/log-fatal`, content);
};

export default InformationLog;