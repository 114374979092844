
export const getLocalStorage = () => {

    var data = {};
    try {
        data = JSON.parse(localStorage.getItem('account'));
        if (!data) data = {};
    }
    catch (err)
    { }
    return data;
}


export const setLocalStorage = (accessToken, accountNumber, contactName) => {
    const account = {
        token: accessToken,
        accountNumber: accountNumber,
        name: contactName
    }

    localStorage.setItem('account', JSON.stringify(account));
}


export const getAccessToken = () => {
    const account = getLocalStorage();
    return account.token;
}
export const getAccountNumber = () => {
    const account = getLocalStorage();
    return account.accountNumber;
}