import React, { useCallback, useEffect, useState, useRef } from 'react';
import { PoundCurrency } from '../../../Shared/EncodingManipulation';
import { fetchCustomerDetails, updateCustomerDetails } from '../../../../lib/api/customer-details';
import { ReactComponent as BlackInverseTick } from '../../../Shared/svgs/tick-ef-black.svg'
import SkeletonCustomer from './Skeleton';
import moment from 'moment';
import DirectDebitMandate from '../../../Shared/DirectDebitMandate';
import DeviceAndBrowserDetector from '../../../../lib/api/deviceandbrowserdetector';

const EditableInput = ({ editing, value, name, required, maxlength }) => {
    const editingInputClasses = "p-1 border-2 text-everflow-black border-everflow-magenta rounded"
    const notEditingInputClasses = "break-all font-bold"
    const [empty, setEmpty] = useState(false)

    return <>
        {editing && (
            <>
                <input
                    disabled={!editing}
                    id={`CustomerDashboard-${name}`}
                    name={name}
                    className={editingInputClasses}
                    defaultValue={value}
                    maxLength={maxlength}
                    required={required}
                    onChange={(e) => {
                        let val = e.target.value; if (val.length == 0) { setEmpty(true) } else { setEmpty(false) }
                    }}
                ></input>
                {empty && required && <span className="text-everflow-magenta">This field is required</span>}
            </>
        )}
        {!editing && (<span id={`CustomerDashboard-${name}`} className={notEditingInputClasses}>{value}</span>)}
    </>
}

const EditableEmail = ({ editing, value, name, required, maxlength, onChange }) => {
    const editingInputClasses = "p-1 border-2 text-everflow-black border-everflow-magenta rounded"
    const notEditingInputClasses = "break-all font-bold"
    const [empty, setEmpty] = useState(false)

    return <>
        {editing && (
            <>
                <input
                    disabled={!editing}
                    id={`CustomerDashboard-${name}`}
                    name={name}
                    className={editingInputClasses}
                    defaultValue={value}
                    maxLength={maxlength}
                    required={required}
                    type="email"
                    onChange={(e) => {
                        let val = e.target.value; if (val.length == 0) { setEmpty(true) } else { setEmpty(false) }
                        if (typeof onChange == 'function') { onChange() }
                    }}
                ></input>
                {empty && required && <span className="text-everflow-magenta">This field is required</span>}
            </>
        )}
        {!editing && (<p
            id={`CustomerDashboard-${name}`}
            className={notEditingInputClasses}
        >{value}</p>)}
    </>
}

const EditablePhone = ({ editing, value, name, required, maxlength }) => {
    const editingInputClasses = "p-1 border-2 text-everflow-black border-everflow-magenta rounded"
    const notEditingInputClasses = "break-all font-bold"
    const [empty, setEmpty] = useState(false)

    return <>
        {editing && (
            <>
                <input
                    disabled={!editing}
                    id={`CustomerDashboard-${name}`}
                    name={name}
                    className={editingInputClasses}
                    defaultValue={value}
                    maxLength={maxlength}
                    required={required}
                    pattern="((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}"
                    onChange={(e) => {
                        let val = e.target.value; if (val.length == 0) { setEmpty(true) } else { setEmpty(false) }
                    }}
                ></input>
                {empty && required && <span className="text-everflow-magenta">This field is required</span>}
            </>
        )}
        {!editing && (<p
            id={`CustomerDashboard-${name}`}
            className={notEditingInputClasses}
        >{value}</p>)}
    </>
}

const CombineEmails = (addresses) => {
    return addresses.filter(a => a !== "" && a !== undefined).join('|')
}

const getEmailsFromDom = () => {
    const billingEmails = ['BillingEmail', 'BillingEmail1', 'BillingEmail2', 'BillingEmail3', 'BillingEmail4']
    let emailData = {}

    billingEmails.forEach(fName => {
        const domEl = document.getElementById(`CustomerDashboard-${fName}`)
        if (domEl && domEl.value) {
            emailData[fName] = domEl.value;
        }
    })

    const CombinedBillingEmail = CombineEmails([
        emailData.BillingEmail,
        emailData.BillingEmail1,
        emailData.BillingEmail2,
        emailData.BillingEmail3,
        emailData.BillingEmail4
    ])

    return CombinedBillingEmail
}

const Customer = () => {
    const [loadError, setLoadError] = useState();
    const [pageData, setPageData] = useState();
    const [loading, setLoading] = useState();
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [additionalEmails, setAdditionalEmails] = useState(0);
    const formRef = useRef();
    const billingEmailMaxLength = 150;
    const [errors, setErrors] = useState([]);
    const deviceAndBrowser = new DeviceAndBrowserDetector();

    const loadData = useCallback(async () => {
        setLoading(true)
        await fetchCustomerDetails().then((resp) => {
            if (loadError) setLoadError(false);
            setPageData(resp)
            setLoading(false)
        })
            .catch(err => {
                setLoading(false)
                setLoadError(true);
            })
    }, [setLoading, setPageData, setLoadError, loadError])

    useEffect(() => {
        loadData().then(r => r);
    }, [loadData]);

    useEffect(() => {
        if (pageData == undefined) {
            return;
        }

        if (pageData.contact.billingEmail.indexOf('|') >= 0) {
            let emails = pageData.contact.billingEmail.split('|')
            if (emails.length > 5) {
                emails = emails.slice(0, 5);
            }
            setAdditionalEmails(emails.length - 1);
        }
    }, [pageData]);

    if (loading) return (
        <SkeletonCustomer />
    )

    if (loadError) return (
        <div className="my-100px mt-50px sm:mt-100px wrapper text-center sm:text-left">
            There was an error loading this page, please click <span onClick={loadData} className="text-everflow-magenta underline cursor-pointer">here</span> to try again.
        </div>
    )

    if (!pageData) return null;

    const billingEmailsArray = pageData.contact.billingEmail.split('|')

    const onCancelEdit = () => {
        const inputs = document.getElementsByTagName("input")
        for (var inp of inputs) {
            if (inp.dataset && inp.dataset.originalValue) {
                inp.value = inp.dataset.originalValue
            }
        }
        const textareas = document.getElementsByTagName("textarea")
        for (var inp of textareas) {
            if (inp.dataset && inp.dataset.originalValue) {
                inp.value = inp.dataset.originalValue
            }
        }
        setEditing(false)
    }

    const onSave = async () => {
        setSaving(true)
        
        const fields = ['ContactName', 'ContactEmail', 'BillingEmail', 'ContactNumber', 'MobileNumber', 'OtherMobileNumber', 'BillingAddressLine1', 'BillingAddressLine2', 'BillingAddressLine3', 'BillingTownOrCity', 'BillingCounty', 'BillingPostcode']
        let data = {}

        fields.forEach(fName => {
            const domEl = document.getElementById(`CustomerDashboard-${fName}`)
            if (domEl && domEl.value) {
                data[fName] = domEl.value;
            }
        })

        if (formRef.current.checkValidity() == false) {
            setSaving(false)
            return
        }

        const CombinedBillingEmail = getEmailsFromDom();

        if (CombinedBillingEmail.length >= billingEmailMaxLength) {
            setSaving(false)
            alert(`Combined billing email addresses length must be under ${billingEmailMaxLength} characters.`)
            return
        }

        data.BillingEmail = CombinedBillingEmail


        try {
            const res = await updateCustomerDetails(data)
            setSuccess(true);
            setEditing(false)
            setSaving(false)
            loadData()
        } catch (err) {
            console.warn(err)
        }
    }

    const onBillingEmailChange = () => {
        const fullEmail = getEmailsFromDom()
        if (fullEmail.length > billingEmailMaxLength) {
            setErrors([...errors, 'billingEmailLength'])
        } else {
            const newErrors = [...errors].filter((e) => { return e !== 'billingEmailLength' })
            setErrors(newErrors)
        }
    }

    return (
        <form ref={formRef}>
            <div className="max-w-1000px mx-4 md:mx-auto mb-4">
                <h2 className="text-2xl mt-8 text-everflow-black font-bold">Your details</h2>

                <div className="text-everflow-black mt-8 p-4 border-2 rounded-lg border-everflow-magenta flex flex-col gap-2">

                    <div className="customer-details-grid grid gap-4 flex-grow">
                        <div className="flex flex-col">
                            <p>Account</p>
                            <span id="AccountName" className="font-bold">{pageData.overview.accountNumber}</span>
                        </div>

                        <div className="flex flex-col">
                            <p>Company name</p>
                            <span id="CompanyName" className="font-bold">{pageData.overview.companyName}</span>
                        </div>

                        <div className="flex flex-col">
                            <p>Contact name</p>
                            <EditableInput name="ContactName" required maxlength={50} editing={editing} value={pageData.overview.contactName}></EditableInput>
                        </div>

                        <div className="flex flex-col">
                            <p>Contact email</p>
                            <EditableEmail name="ContactEmail" maxlength={150} required editing={editing} value={pageData.contact.contactEmail}></EditableEmail>
                        </div>

                        <div className="flex flex-col">
                            <p>Billing email</p>
                            <EditableEmail name="BillingEmail" maxlength={150} required editing={editing} value={billingEmailsArray[0]} onChange={onBillingEmailChange} ></EditableEmail>
                            {Array.from({ length: additionalEmails }).map((i, index) => <EditableEmail key={index} name={`BillingEmail${index + 1}`} maxlength={billingEmailMaxLength} editing={editing} onChange={onBillingEmailChange} value={billingEmailsArray[index + 1]} />)}
                            {editing && additionalEmails < 4 && <button className='text-everflow-magenta font-bold mt-2 p-2 flex items-center' onClick={(e) => { e.preventDefault(); setAdditionalEmails(state => state + 1) }}><img height='10' width='10' className='mr-2' src='/assets/icons/plus.svg' style={{ display: 'inline-block' }} /> Add additional email</button>}
                            {editing && errors.indexOf('billingEmailLength') >= 0 && <p>You have exceeded the max limit of {billingEmailMaxLength} characters for the billing email addresses</p>}
                        </div>

                        <div className="flex flex-col">
                            <p>Contact number</p>
                            <EditableInput name="ContactNumber" maxlength={50} required editing={editing} value={pageData.contact.contactPhone}></EditableInput>
                        </div>

                        <div className="flex flex-col">
                            <p>Mobile number</p>
                            <EditablePhone name="MobileNumber" maxlength={50} editing={editing} value={pageData.contact.mobilePhone}></EditablePhone>
                        </div>

                        <div className="flex flex-col">
                            <p>Other mobile</p>
                            <EditablePhone name="OtherMobileNumber" maxlength={50} editing={editing} value={pageData.contact.otherMobilePhone}></EditablePhone>
                        </div>

                        <div className="flex flex-col customer-details-billing">
                            <p>Billing address</p>
                            <EditableInput name="BillingAddressLine1" maxlength={150} required editing={editing} value={pageData.overview.billingAddress.address1} />
                            <EditableInput name="BillingAddressLine2" maxlength={150} editing={editing} value={pageData.overview.billingAddress.address2} />
                            <EditableInput name="BillingAddressLine3" maxlength={150} editing={editing} value={pageData.overview.billingAddress.address3} />
                            <p className="mt-4">Town</p>
                            <EditableInput name="BillingTownOrCity" maxlength={150} editing={editing} value={pageData.overview.billingAddress.townOrCity} />
                            <p className="mt-4">County</p>
                            <EditableInput name="BillingCounty" maxlength={150} editing={editing} value={pageData.overview.billingAddress.county} />
                            <p className="mt-4">Postcode</p>
                            <EditableInput name="BillingPostcode" maxlength={20} required editing={editing} value={pageData.overview.billingAddress.postcode} />
                        </div>

                    </div>

                    <div className="text-everflow-magenta font-bold self-end text-right flex gap-4">
                        {!editing && !saving && (
                            <a id="EditDetailsButton" onClick={() => { setEditing(true); setSuccess(false) }}>Edit details</a>
                        )}
                        {editing && !saving && (
                            <>
                                <a id="EditDetailsCancel" onClick={onCancelEdit}>Cancel</a>
                                <button className={errors.length > 0 ? 'text-everflow-grey font-bold' : 'font-bold'} disabled={errors.length > 0} id="EditDetailsConfirm" onClick={() => onSave()}>Confirm details</button>
                            </>
                        )}
                    </div>
                </div>

                <div id={`customerdetails-savedetails-success`} className={`p-8 mt-2 bg-everflow-green rounded-18px text-everflow-black flex items-center justify-around gap-4 mb-4 ${success ? "" : "hidden"}`}>
                    <BlackInverseTick className="w-56px"></BlackInverseTick>
                    <p className="text-2xl font-bold">Thank you, your details have been updated</p>
                </div>

                <div className="mt-2 p-4 border-2 rounded-lg border-everflow-magenta bg-everflow-magenta text-everflow-white flex flex-col sm:flex-row justify-between gap-4">

                    <div className="flex flex-col basis-1/4 flex-grow-0">
                        <p>Account balance</p>
                        <div id="AccountBalance" className="font-bold break-words">{PoundCurrency(pageData.accountBalance.accountBalance)}</div>
                    </div>

                    <div className="flex flex-col basis-1/4 flex-grow-0">
                        <p>Expected billing date</p>
                        <div id="ExpectedBillDate" className="font-bold break-words">{`${moment().date(pageData.accountBalance.expectedBilling).format('Do')} of Month`}</div>
                    </div>

                    <div className="flex flex-col basis-1/4 flex-grow-0">
                        <p>Payment method</p>
                        <div id="PaymentMethod" className="font-bold break-words">{pageData.accountBalance.paymentMethod == 'DD' ? 'Direct Debit' : 'Manual Payment'}</div>
                    </div>

                    <div className="flex flex-col basis-1/4 flex-grow-0">
                        <p>Billing term</p>
                        <div id="BillingTerm" className="font-bold break-words">{pageData.accountBalance.billingTerms}</div>
                    </div>
                </div>

                {pageData.accountBalance.paymentMethod !== 'DD' && <DirectDebitMandate />}

                <div className="mt-2 p-4 border-2 rounded-lg border-everflow-magenta bg-everflow-magenta text-everflow-white flex flex-col sm:flex-row justify-between gap-4">
                    <div className="flex flex-col basis-1/4 flex-grow-0">
                        <p>Browser</p>
                        <div id="DeviceInfo" className="font-bold break-words">{deviceAndBrowser.productName} on {deviceAndBrowser.operatingSystem}.</div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Customer;