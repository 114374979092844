import React from "react";
import {ReactComponent as Chevron} from '../../Shared/svgs/chevron-left.svg'

export const SkeletonText = ({className, spacing, inheritFontSize, noPulse}) => {
    if(!spacing) spacing = '6px';
    const fontSize = inheritFontSize ? '1em' : '12px';

    return (
        <div style={{height: fontSize, borderRadius: '.5em',  marginBottom: spacing}} className={`inline-block bg-gray-default-500 ${noPulse ? '' : ' animate-pulse'}${className ? ' ' + className : ''}`}></div>
    )
}

export const SkeletonBlock = ({rows}) => {
    if(!rows) rows = 1;

    const rowBlock = [];
    for(var i = 1; i < rows; i++) rowBlock.push(<SkeletonText />)
    rowBlock.push(<SkeletonText  className="w-1/3" />)
    return (
        <div>
            {rowBlock}
        </div>
    )
}

export const SkeletonInput = ({noPulse, className}) => (
    <div className={`h-56px rounded-28px border-2 border-gray-default-500${noPulse ? '' : ' animate-pulse'}${className ? ' ' + className : ''}`}></div>
)



export const SideSkeleton = () => (
    <div className="px-20px py-40px rounded-18px border-gray-default-500 border-2 text-center animate-pulse">
        <SkeletonText noPulse spacing="20px" className="text-12px w-2/3 mx-auto" />
        <SkeletonText noPulse className="text-13px w-3/4 mx-auto" />
        <SkeletonText noPulse spacing="20px"  className="text-12px w-1/2 mx-auto mb-20px" />
        <SkeletonText noPulse className="text-13px w-3/4 mx-auto" />
        <SkeletonText noPulse spacing="20px" className="text-12px w-2/3 mb-20px mx-auto" />
        <SkeletonText noPulse className="text-13px w-3/4 mx-auto" />
        <SkeletonText noPulse spacing="20px" className="text-12px w-2/3 mb-20px mx-auto" />
        <SkeletonText noPulse className="text-13px w-3/4 mx-auto" />
        <SkeletonText noPulse spacing="20px" className="text-12px w-2/3 mx-auto" />
        <SkeletonText noPulse className="text-13px w-3/4 mx-auto" />
        <SkeletonText noPulse className="text-12px w-2/3 mx-auto" />
    </div>
)

export const SkeletonPagination = ({pages, selected, className}) => {

    console.log('skeleton selected', selected)
    console.log('skeleton pages', pages)


    if(!pages || pages === 1) return null;
    
    // build array or all pages
    var buttons = []
    for(var i = 1; i <= pages; i++) buttons.push(i);

    // check which pages to display, if more than 5
    buttons = (() => {

        if(pages <= 5) return buttons;
        
        // if selected in first 3
        if(selected < 4) return [...buttons.slice(0, 5), '...']

        // if selected in last 3
        if((pages - selected) < 3) return ['...', ...buttons.slice(pages - 5, pages)]

        return ['...', ...buttons.slice(selected - 3, selected + 2), '...']
    })()
        
   
    return (
        <div className={`inline-flex items-center${className ? ' ' + className : ''}`}>
            <button className={`mr-40px w-50px h-50px border border-gray-default-500 text-gray-default-500 pointer-events-none rounded-25px flex flex-col items-center justify-center`}><Chevron className="fill-current" /></button>
            {buttons.map((i, index) => {

                // if selected
                if(i == selected) return <span key={`b-${index}`} className=" w-50px h-50px border border-gray-default-500 rounded-25px flex flex-col items-center justify-center bg-gray-default-500 text-white"></span>
                
                // if first dots
                if(i == '...' && index == 0) return <span key={`b-${index}`}  style={{color: '#747385'}} className="w-50px h-50px border border-transparent rounded-25px flex flex-col items-center justify-center hover:underline cursor-pointer"><SkeletonText className="w-30px" /></span>
                // if last dots
                if(i == '...' && index == buttons.length - 1) return <span key={`b-${index}`}  style={{color: '#747385'}} className="w-50px h-50px border border-transparent rounded-25px flex flex-col items-center justify-center hover:underline cursor-pointer"><SkeletonText className="w-50px" /></span>

                // default
                return <span key={`b-${index}`}  style={{color: '#747385'}} className="w-50px h-50px border border-transparent rounded-25px flex flex-col items-center justify-center hover:underline cursor-pointer"><SkeletonText className="w-10px" /></span>
            })}

            <button className={`ml-40px w-50px h-50px border border-gray-default-500 text-gray-default-500 pointer-events-none rounded-25px flex flex-col items-center justify-center`}><Chevron className="fill-current" style={{transform: 'scaleX(-1)'}} /></button>
        </div>
    )
}