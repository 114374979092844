import React, { useCallback, useEffect, useState } from 'react';
import PaymentButton from '../../../Shared/PaymentButton';
import PaymentAmountChoice from '../../../Shared/Payment/AmountChoice';
import { PoundCurrency } from '../../../Shared/EncodingManipulation';
import { useRouteMatch } from "react-router-dom";
import { ReactComponent as WaterIcon } from '../../../Shared/svgs/water-ef-magenta.svg';
import { fetchCustomerDetails } from '../../../../lib/api/customer-details';
import { searchSPIDs } from '../../../../lib/api/premises';
import useConfig from '../../../../lib/hooks/useConfig';
import moment from 'moment';

const Home = () => {

    const [loadError, setLoadError] = useState();
    const [customerData, setCustomerData] = useState();
    const [meterData, setMeterData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [paymentEnabled, setPaymentEnabled] = useState(false);
    const [showPaymentAmountChoice, setShowPaymentAmountChoice] = useState(false);
    const match = useRouteMatch('/dashboard/:section');
    const tab = match && match.params && match.params.section;
    const ccConfig = useConfig('card-payment-config');

    const loadCustomerDetails = useCallback(async () => {
        await fetchCustomerDetails()
            .then((resp) => {
                setCustomerData(resp);
            })
            .catch(err => {
                console.log('Error loading customer data', err);
            });
    }, [setCustomerData, setLoadError, loadError]);

    const loadMeterDetails = useCallback(async () => {
        await searchSPIDs('', 1, 10000)
            .then((resp) => {
                setMeterData(resp);
            })
            .catch(err => {
                console.log('Error loading meter data', err);
            })
    }, [setMeterData, setLoadError, loadError]);

    const togglePaymentAmountChoice = () => {
        console.log("Toggle function called");
        setShowPaymentAmountChoice(!showPaymentAmountChoice);
    };

    useEffect(() => {
        loadCustomerDetails().then(r => r);
        loadMeterDetails().then(r => r);
    }, [loadCustomerDetails, loadMeterDetails]);

    useEffect(() => {
        if (ccConfig) {
            setPaymentEnabled(ccConfig.cardPaymentEnabled);
            setIsLoading(false);
        }
    }, [ccConfig]);

    if (loadError) return (
        <div className="my-100px mt-50px sm:mt-100px wrapper text-center sm:text-left">
            There was an error loading this page, please click <span onClick={loadCustomerDetails} className="text-everflow-magenta underline cursor-pointer">here</span> to try again.
        </div>
    );

    if (!customerData || !meterData || !ccConfig) return null;

    return (
        <>
            <div className="mt-50px sm:mt-100px wrapper">
                <div className="bg-everflow-white text-everflow-black mb-20px inline-flex justify-between items-center">
                    <div className="w-2/3">
                        <p className="text-50px">Hello, <span className="text-everflow-magenta"><b id="DashboardContactName">{customerData.overview.contactName}</b></span></p>
                        <p>Within the portal, you can access and update your account details, manage your premises and meters, view your usage and download your invoices.</p>
                    </div>
                    <WaterIcon className="w-100px fill-current" />
                </div>

                <div className="bg-everflow-white text-everflow-black p-32px mb-20px rounded-18px border-2 border-everflow-magenta w-full">
                    <div className="bar">
                        <div className="w-full sm:w-1/4">
                            <p className="mb-2">Account</p>
                            <p><b id="DashboardAccountNumber">{customerData.overview.accountNumber}</b></p>
                        </div>
                        <div className="w-full sm:w-1/4">
                            <p className="mb-2">No. of locations</p>
                            <p><b id="DashboardLocationCount">{meterData.data.data.totalResults}</b></p>
                        </div>
                        <div className="w-full sm:w-1/4">
                            <p className="mb-2">No. of meters</p>
                            <p><b id="DashboardMeterCount">{meterData.data.data.results.reduce((sum, current) => {
                                return sum + current.metersOnSPID;
                            }, 0)}</b></p>
                        </div>
                        <div className="w-full sm:w-1/4">
                            <p className="mb-2">Account balance</p>
                            <div className="flex justify-between flex-col lg-flex-row">
                                <b>
                                    <span id="DashboardAccountBalance" className="text-everflow-magenta">
                                        {PoundCurrency(customerData.accountBalance.accountBalance)}
                                    </span>
                                </b>
                                {!isLoading && (tab === 'home' && paymentEnabled) && (
                                    <PaymentButton enabled={customerData.accountBalance.accountBalance > 0} togglePaymentAmountChoice={togglePaymentAmountChoice} amount={customerData.accountBalance.accountBalance} />
                                )}
                            </div>
                        </div>
                    </div>
                    {showPaymentAmountChoice && <PaymentAmountChoice accountBalance={customerData.accountBalance.accountBalance} />}
                </div>

                <div className="bg-everflow-magenta text-everflow-white p-32px mb-20px rounded-18px border-2 border-everflow-magenta w-full">
                    <div className="flex bar">
                        <div className="w-full sm:w-1/4">
                            <p className="mb-2">Contract end date</p>
                            <p><b id="DashboardContractEnd">{moment(customerData.payment.contractEnd).subtract(1, 'days').format('DD.MM.YYYY')}</b></p>
                        </div>
                        <div className="w-full sm:w-1/4">
                            <p className="mb-2">Expected billing date</p>
                            <p><b id="DashboardBillDate">{customerData.accountBalance.expectedBilling ? `${moment().date(customerData.accountBalance.expectedBilling).format('Do')} of Month` : ''}</b></p>
                        </div>
                        <div className="w-full sm:w-1/4">
                            <p className="mb-2">Payment method</p>
                            <p><b id="DashboardPaymentMethod">{customerData.accountBalance.paymentMethod == 'DD' ? 'Direct Debit' : 'Manual Payment'}</b></p>
                        </div>
                        <div className="w-full sm:w-1/4">
                            <p className="mb-2">Billing term</p>
                            <p><b id="DashboardBillTerm">{customerData.accountBalance.billingTerms}</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home