import WebAPI from './api-calls';
import { rateHandler } from './rate-handler';

export const paymentUrl = async ({ payAmount }) => {

    const task = async (res, rej) => {
        console.log('ratelimit paymentUrl', payAmount)

        if (!payAmount) return rej(new Error('Missing data from request'))

        const webApi = new WebAPI();
        await webApi.postToWebApiWithAccessTokenAsync(`/api/card-payment-url`, JSON.stringify({ "Amount": parseFloat(payAmount) }))
            .then(resp => {
                if (!resp) {
                    return res({ success: true });
                }

                return res({ success: true, data: resp.data });
            })
            .catch(err => {
                console.log(err);
                rej(err);
            })
    }
    return rateHandler(task);
}