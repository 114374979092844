import React, { useEffect } from 'react';
import WhiteButton from '../../../Shared/WhiteButton';
import { ReactComponent as HomeOn } from '../../../Shared/svgs/wave-ef-white.svg';
import { ReactComponent as HomeOff } from '../../../Shared/svgs/wave-ef-magenta.svg';
import { ReactComponent as MetersOn } from '../../../Shared/svgs/meter-ef-white.svg';
import { ReactComponent as MetersOff } from '../../../Shared/svgs/meter-ef-magenta.svg';
import { ReactComponent as BillsOn } from '../../../Shared/svgs/bill-ef-white.svg';
import { ReactComponent as BillsOff } from '../../../Shared/svgs/bill-ef-magenta.svg';
import { useHistory, useRouteMatch } from "react-router-dom";
import useConfig from '../../../../lib/hooks/useConfig';

const DashboardContent = ({ children, helpLink }) => {
    const match = useRouteMatch('/dashboard/:section');
    const history = useHistory();
    const tab = match && match.params && match.params.section;
    const ccConfig = useConfig('card-payment-config');

    useEffect(() => {
        if (tab && ['home', 'meters', 'billing', 'customer'].indexOf(tab) === -1) history.push('/404');
    }, [tab, ccConfig])

    return (
        <>
            <div className="wrapper grid grid-cols-1 md:grid-cols-3 gap-20px md:gap-40px mt-60px">
                <WhiteButton
                    id='dashboard-home-nav-btn'
                    selected={tab === 'home'}
                    onClick={() => history.push('/dashboard/home')}
                >
                    {tab === 'home' ? <HomeOn className="mr-20px w-56px" /> : <HomeOff className="mr-20px w-56px" />}
                    Dashboard
                </WhiteButton>
                <WhiteButton
                    id='dashboard-meters-nav-btn'
                    selected={tab === 'meters'}
                    onClick={() => history.push('/dashboard/meters')}
                >
                    {tab === 'meters' ? <MetersOn className="mr-20px w-56px" /> : <MetersOff className="mr-20px w-56px" />}
                    Your meters
                </WhiteButton>
                <WhiteButton
                    id='dashboard-billing-nav-btn'
                    selected={tab === 'billing'}
                    onClick={() => history.push('/dashboard/billing')}
                >
                    {tab === 'billing' ? <BillsOn className="mr-20px w-56px" /> : <BillsOff className="mr-20px w-56px" />}
                    Your bills
                </WhiteButton>
            </div>
            <div>
                {children({ tab, helpLink })}
            </div>
        </>
    )
}

export default DashboardContent
