import React, {useState} from "react"
import InputText from "../../../../Shared/FormComponents/InputText";
import InputButton from "../../../../Shared/FormComponents/InputButton";
import { useHistory } from "react-router-dom";
import Form from '../../../../Shared/Form';
import { stripUsernameChars } from '../../../../Shared/StringManipulation';
import { createAccount, getValidAccountNumber } from "../../../../../lib/api/user";
import * as Yup from 'yup';

const CreateForm = () => {
    const history = useHistory();
    const [loading, setLoading] = useState();
    const [success, setSuccess] = useState()
    
    const submit = ({account}, {setErrors, setStatus}) => {
        setLoading(true);
        const validAccountNumber = getValidAccountNumber(account);
        createAccount(validAccountNumber)
        .then(()=>{
            setSuccess(true)
            setTimeout(()=>history.push('/login/check-email'), 1000)
        })
        .catch(err => {
            setLoading(false);
            setSuccess(false)
            if (err.formStatus == "Please check your Account Number") {
                setStatus(`You have already registered for this account, please log in <a class='underline' href='/login'>here</a>`)
            } else {
                setStatus(err.formStatus)
            }
        })
    }

    return (
        <Form
            onSubmit={submit}
            className="grid grid-cols-1 gap-y-24px justify-items-center"
            validationSchema={formSchema}
        >
            {({values, setFieldValue, errors, handleSubmit, handleChange}) => (
                <>
                    <InputText
                        className="w-full rounded-28px"
                        id="account"
                        error={errors && errors.account}
                        label="Account number"
                        placeholder="e.g. EFW000000-00"
                        tick={success}
                        value={values.account}
                        onChange={ev => stripUsernameChars(ev.target.value, val => setFieldValue('account', val))}
                    />
                    <InputButton
                        loading={loading}
                        className="w-full"
                        onClick={handleSubmit}
                        label="Create account"
                    />
                </>
            )}

        
        </Form>
    )
}

const formSchema = Yup.object().shape({
    account: Yup.string().required('Please enter your account number')
})

export default CreateForm
