import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import {ReactComponent as Chevron} from '../../svgs/chevron-left.svg';
import YearPicker from './YearPicker';
import {motion, AnimatePresence } from 'framer-motion';
import { useDebounce } from '../../../../lib/hooks/useDebounce';
import TextBox from './TextBox';
import ReactDOM  from 'react-dom';

const variantsLeft = {
    hidden: { opacity: 0, x: 100, y: 0, position: 'absolute', width: '100%' },
    enter: { opacity: 1, x: 0, y: 0,  position: 'relative', width: '100%' },
    exit: { opacity: 0, x: -100, y: 0,  position: 'absolute', width: '100%' },
}

const variantsRight = {
    hidden: { opacity: 0, x: -100, y: 0, position: 'absolute', width: '100%' },
    enter: { opacity: 1, x: 0, y: 0,  position: 'relative', width: '100%' },
    exit: { opacity: 0, x: 100, y: 0,  position: 'absolute', width: '100%' },
  }

const DatePicker = ({label, onChange, format, thin, error, onBlur, id, tick, className}) => {

    const [selected, setSelected] = useState(moment());
    const [showPicker, setShowPicker] = useState();

    const [height, setHeight] = useState(0);
    const [show, setShow] = useDebounce(1);

    const [position, setPosition] = useState({top: 0, left: 0})

    console.log('picker', showPicker);

    const ref = useRef();
    
    const inputRef = useRef();

    const resetHeight = () => {
        setHeight(showPicker ? ref.current.clientHeight : 0)
    }

    useEffect(()=>{
        resetHeight()
    }, [showPicker])

    const checkClick = ev => {
        if(!showPicker) return; 
        if(ref.current.contains(ev.target)) return;
        setShowPicker(false);
    }

    useEffect(()=>{
        if(showPicker){

            const position = inputRef.current.getBoundingClientRect()

            const top = position.top + window.scrollY
            const left = position.left + window.scrollX

            setPosition({top, left});


            window.addEventListener('click', checkClick);
            return () => {
                window.removeEventListener('click', checkClick);

            }
        }else{
            setPosition({top: 0, left: 0});
        }
    }, [showPicker])

    useEffect(()=>{
        setShowPicker(false);

        var returnVal = selected;

        if(returnVal){
            returnVal = format ? returnVal.format(format) : returnVal.toISOString();

        }

        onChange && onChange(returnVal);

    }, [selected])

    const hideOnResize = () => {
        setShowPicker(false);
    }

    useEffect(()=>{
        window.addEventListener('resize', hideOnResize)
        return () =>{
            window.removeEventListener('resize', hideOnResize)
        }
    }, [hideOnResize])

    const positionStyle = {top: position.top + 76, left: position.left}
    if(window.innerWidth < 950) {
        positionStyle.left = '50%';
        positionStyle.transform = 'translateX(-50%)';
    }

    return(
        <div>
            <TextBox
                readOnly
                thin={thin}
                id={id}
                value={selected ? selected.format('DD.MM.YYYY') : ''}
                label={label}
                onClick={() => setShowPicker(true)}
                ref={inputRef}
                error={error}
                onBlur={onBlur}
                tick={tick}
                className={className}
            />
            <Portal
                divId="date-picker"
            >
                <div
                    className="absolute"
                    style={positionStyle}
                >
                    <div
                        style={{height: height + 'px', opacity: !!height ? 1 : 0}}
                        className="inline-block min-w-300px border-2 border-everflow-magenta rounded-xl overflow-hidden bg-white relative transition-all">
                        <div className="absolute w-full">
                            <DateDisplay
                                resetHeight={resetHeight}
                                ref={ref}
                                show={show}
                                setShow={setShow}
                                selected={selected}
                                onSelect={setSelected}
                                blockFuture={true}
                                blockPast={null}
                            />
                        </div>
                    </div>
                </div>
            </Portal>

        </div>
    )
}


const DateDisplay = React.forwardRef(({show, setShow, selected, onSelect, blockFuture, blockPast, resetHeight}, ref) => {
    if(!show) show = moment()

    const [left, setLeft] = useState();

    const [yearPicker, setYearPicker] = useState();

    const month = show.format('MM');
    const year = show.format('YYYY');

    const daysInMonth = show.daysInMonth();
    const firstDay = moment(show).date(1).format('d');
    const lastDay = moment(show).date(daysInMonth).format('d');

    var cells = [];
    for(var i = 1; i <= daysInMonth; i++) cells.push(i);

    const startExtra = [];
    for(var i = 0; i < firstDay; i++) startExtra.push('');

    const endExtra = [];
    for(var i = 0; i < (6 - lastDay); i++) endExtra.push('')

    cells = [...startExtra, ...cells, ...endExtra];

    const showSelected = (function(){
        if(!(selected && (moment(show).format('YYYY-MM') == moment(selected).format('YYYY-MM')))) return false;
        return moment(selected).format('D')
    })()

    const selectDate = day => {
        if(!day) return;
        onSelect && onSelect(moment(show).date(day));
    }

    const backMonth = () => {
        setLeft(true);
        const newMonth = moment(show).subtract(1, 'month');
        setShow(newMonth);
    }

    const forwardMonth = () => {
        setLeft(false);
        const newMonth = moment(show).add(1, 'month');
        setShow(newMonth);
    }

    useEffect(()=>{
        const to = setTimeout(resetHeight, 100);
        return () => clearTimeout(to);
    }, [show])


    return (
        <div className="bg-white input-datepicker overflow-hidden" ref={ref}>
            <YearPicker
                active={yearPicker}
                close={() => setYearPicker(false)}
                show={show}
                setShow={setShow}
                blockFuture={blockFuture}
            />
            <div className="title-wrapper">
                <button 
                    onClick={()=>setYearPicker(true)}
                    className="month-title">
                        {show.format('MMMM YYYY')}
                    </button>
                <span>
                    <button
                        className="cycle-month"
                        onClick={backMonth}
                    >
                        <Chevron />
                    </button>
                    <button
                        className="cycle-month"
                        onClick={forwardMonth}
                    >
                        <Chevron style={{transform: 'scaleX(-1)'}} />
                    </button>
                </span>
            </div>
            <div className="relative">
                <AnimatePresence>
                    <motion.ul
                        initial="hidden" // Set the initial state to variants.hidden
                        animate="enter" // Animated state to variants.enter
                        exit="exit"
                        variants={!left ? variantsLeft : variantsRight}
                        transition={{ type: 'linear', duration: .2 }}
                        key={show.format('YYYY-MM-DD')}
                        className="">
                        <li className="dow-title">S</li>
                        <li className="dow-title">M</li>
                        <li className="dow-title">T</li>
                        <li className="dow-title">W</li>
                        <li className="dow-title">T</li>
                        <li className="dow-title">F</li>
                        <li className="dow-title">S</li>
                        {cells.map((day, i) => {
                            var blocked = false;
                            const currentDay = moment(`${year}-${month}`).date(day);
                            if(blockFuture && currentDay.format('YYYYMMDD') > moment().format('YYYYMMDD')) blocked = true;
                            if(blockPast && currentDay.format('YYYYMMDD') < moment().format('YYYYMMDD')) blocked = true;
                            
                            const classes = [];
                            if(showSelected && (showSelected == day)) classes.push('selected');
                            if(day) classes.push('day');
                            if(blocked) classes.push('blocked');

                            if(day && currentDay.format('YYYYMMDD') == moment().format('YYYYMMDD')) classes.push('today')

                            return(
                                <li key={`day-${i}-${year}-${month}`} className={`${classes.join(' ')}`} onClick={() => !blocked && selectDate(day)}>
                                    <span className="day-label">{day}</span></li>
                            )
                        })}
                    </motion.ul>
                </AnimatePresence>
            </div>
        </div>
    )
})

const Portal = ({children, divId}) => {

    const alertRoot = document.getElementById(divId);
    return ReactDOM.createPortal(
        children,
        alertRoot
      );
}

export default DatePicker;