import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Redirect, Route } from 'react-router';

import Login from './components/Pages/Login';
import Dashboard from './components/Pages/Dashboard';
import { useLogin } from './lib/hooks/useLogin';
import NotFound from './components/Pages/NotFound';
import Switch from './components/Shared/RouteSwitch';
import Maintenance from './components/Pages/Maintenance';
import { checkMaintenance } from './lib/maintenance/es6';
import PopUp from './components/Shared/PopUp';
import Analytics from './components/Shared/Analytics';
import LiveChatLoader from "./liveChatLoader";
import CardPayment from './components/Pages/CardPayment/index';
import ThankYou from './components/Pages/CardPayment/ThankYou/index';
import Cancel from './components/Pages/CardPayment/Cancel/index';

const App = () => {
    const {loading, account} = useLogin();
    const history = useHistory();
    const location = useLocation();

    const [analyticsCookiesAccepted, setAnalyticsCookiesAccepted] = useState(localStorage.getItem('analyticsCookieControlIndexJS') === 'true');

    const isFramePage = location.pathname.startsWith('/thanks') || location.pathname.startsWith('/cancel')

    useEffect(() => {
        if (analyticsCookiesAccepted) {
            fetch('/api/Configuration/analytics-config')
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch live chat configuration");
                    }
                    return response.json();
                })
                .then(data => {
                    if (window && window.mixpanel && data.enabled) {
                        console.log("Initializing MixPanel")
                        window.mixpanel.init(data.licenseKey, { debug: data.debugMode, track_pageview: true, persistence: 'localStorage' });
                        if (account && account.accountNumber) {
                            window.mixpanel.identify(account.accountNumber);
                        }
                    }
                })
                .catch(err => console.warn(err));
        }
    }, [])

    useEffect(() => {
        if(checkMaintenance() && history && history.location && history.location.pathname !== '/maintenance') {
            history.push('/maintenance');
        }
    }, [history]);

    useEffect(() => {
        if (analyticsCookiesAccepted) {
            if (window && window.mixpanel && window.mixpanel.track) {
                window.mixpanel.track('React Page View', {
                    'page': location.pathname
                })
            }
        }
    }, [location]);

    useEffect(() => {
        const handleAccept = () => {
            localStorage.setItem('analyticsCookieControlIndexJS', 'true');
            setAnalyticsCookiesAccepted(true);
        };
        const handleRevoke = () => {
            localStorage.setItem('analyticsCookieControlIndexJS', 'false');
            setAnalyticsCookiesAccepted(false);
        };

        window.addEventListener('analyticsCookieAccepted', handleAccept);
        window.addEventListener('analyticsCookieRevoked', handleRevoke);

        return () => {
            window.removeEventListener('analyticsCookieAccepted', handleAccept);
            window.removeEventListener('analyticsCookieRevoked', handleRevoke);
        };
    }, []);

    if (loading) return null;

    return (
        <>
            {analyticsCookiesAccepted && !isFramePage && <LiveChatLoader />}
            {analyticsCookiesAccepted && Analytics.init() && <Analytics.RouteTracker />}
            <Switch>
                <Route exact path="/">
                    {account && account.accountNumber ? <Redirect to="/dashboard/home" /> : <Redirect to="/login" />}
                </Route>
                <Route path='/maintenance' render={() => <Maintenance />} />
                <Route path='/login' render={() => <Login />} />
                <Route path='/dashboard' render={() => <Dashboard account={account} />} />
                <Route exact path='/404' render={() => <NotFound />} />
                <Route path='/cardpayment' render={() => <CardPayment/>} />
                <Route path='/thanks' render={() => <ThankYou />} />
                <Route path='/cancel' render={() => <Cancel />} />
            </Switch>
            <PopUp />
        </>
    );
}

export default App;