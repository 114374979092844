import WebAPI from './api-calls';
import moment from 'moment'
import { rateHandler } from './rate-handler';

export const searchSPIDs = async (search, page, perPage) => {
    const task = async (res, rej) => {
        if (!perPage) perPage = 10;

        const request = {
            page,
            perPage,
            search
        }

        const webApi = new WebAPI();
        const url = webApi.getEndpointWithQuerystringPayload('/api/account-search-meterswithcount', request);
        await webApi.getFromWebApiWithAccessTokenAsync(url)
            .then(resp => {
                if (!resp) {
                    return res({ success: true, request });
                }

                return res({ success: true, data: resp.data, request: request })
            })
            .catch(err => {
                console.log('listener:', err.response.status, err);
                rej(err);
            })
    }
    return rateHandler(task);
}

export const fetchMeterData = (spid) => {
    const task = async (res, rej) => {
        try {
            const meters = listMeters(spid);
            const locations = listPremises(spid);

            const [meterResp, locationResp] = await Promise.all([meters, locations]);

            const response = {
                meters: meterResp.data.data,
                location: locationResp.data
            }

            return res(response);

        } catch (err) {
            return rej(err);
        }
    }
    return rateHandler(task);
}

export const listPremises = async (spid) => {
    const task = async (res, rej) => {
        if (!spid) rej();

        console.log('editmeters call list premises');

        const webApi = new WebAPI();
        const url = webApi.getEndpointWithQuerystringPayload('/api/account-premises-data', { spid })
        await webApi.getFromWebApiWithAccessTokenAsync(url)
            .then(resp => {
                if (!resp) {
                    return res({ success: true });
                }

                return res({ success: true, data: resp.data })
            })
            .catch(err => {
                console.log('listener:', err.response.status, err);
                rej(err);
            })
    }
    return rateHandler(task);
}

export const listMeters = async (spid) => {
    const task = async (res, rej) => {
        if (!spid) rej();

        console.log('editmeters call listmeters call');

        const webApi = new WebAPI();
        const url = webApi.getEndpointWithQuerystringPayload('/api/account-list-meters', { generalSpid: spid })
        await webApi.getFromWebApiWithAccessTokenAsync(url)
            .then(resp => {
                if (!resp) {
                    res({ success: true });
                }

                return res({ success: true, data: resp.data })
            })
            .catch(err => {
                console.log('listener:', err.response.status, err);
                rej(err);
            })
    }
    return rateHandler(task);
}

export const submitReading = async ({ generalSpid, spidMeterId, meterSerial, reading, date, validationPassed, failureReasons }) => {
    date = date ? moment(date, 'DD.MM.YYYY').toISOString() : null;

    const task = async (res, rej) => {
        console.log('ratelimit submitreading', generalSpid, spidMeterId, meterSerial, reading, date, validationPassed, failureReasons)

        if (!generalSpid) return rej(new Error('Missing generalSpid from request'));
        if (!spidMeterId) return rej(new Error('Missing spidMeterId from request'));
        if (!meterSerial) return rej(new Error('Missing meterSerial from request'));
        if (!reading) return rej(new Error('Missing reading from request'));
        if (!date) return rej(new Error('Missing date from request'));
        if (validationPassed == null || validationPassed == "null") return rej(new Error('Missing validationPassed from request'));
        if (!failureReasons) return rej(new Error('Missing failureReasons from request'));

        const readDate = new moment(date).format('YYYY-MM-DD');
        const webApi = new WebAPI();

        await webApi.postToWebApiWithAccessTokenAsync(`/api/account-submit-reading`, JSON.stringify({ generalSpid, spidMeterId, meterSerial, readDate, reading, validationPassed, failureReasons }))
            .then(resp => {
                if (!resp) {
                    return res({ success: true });
                }

                return res({ success: true, data: resp.data });
            })
            .catch(err => {
                console.log(err);
                rej(err);
            })

    }
    return rateHandler(task);
}

export const validateReading = async ({ spidMeterId, meterSerial, reading, date }) => {
    date = date ? moment(date, 'DD.MM.YYYY').toISOString() : null;

    const task = async (res, rej) => {
        console.log('ratelimit validatereading', spidMeterId, meterSerial, reading, date)

        if (!spidMeterId || !meterSerial || !reading || !date) return rej(new Error('Missing data from request'));

        const readDate = new moment(date).format('YYYY-MM-DD');
        const webApi = new WebAPI();

        await webApi.postToWebApiWithAccessTokenAsync(`/api/account-validate-reading`, JSON.stringify({ spidMeterId, meterSerial, readDate, reading}))
            .then(resp => {
                if (!resp) {
                    return res({ success: true });
                }

                return res({ success: true, data: resp.data });
            })
            .catch(err => {
                console.log(err);
                rej(err);
            })

    }
    return rateHandler(task);
}