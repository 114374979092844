import Axios from 'axios';
import { logout } from './user';
import {checkMaintenance} from "../maintenance/es6";

const client = Axios.create({
})

client.interceptors.request.use(function (request){
   if(checkMaintenance())
   {
       logout().then(()=>{
           document.location = '/maintenance'
       })
   }
   return request;
});

client.interceptors.response.use(function (response) {
        return response;
    }, function (error) {

        console.log('error response in axios client', error.response.status);

        // check and log out on expired session
        if(error.response.status === 401 && (error.response.data === 'TokenExpired' || error.response.data === 'Unauthorized')){
            logout().then(()=>{
                document.location = '/login?session-expired=1'
            })
            return Promise.reject(error);
        }else if(error.response.status === 401 && (error.response.data === 'Under maintenance.')){
            logout().then(()=>{
                document.location = '/maintenance'
            })
            return Promise.reject(error)
        }
        return Promise.reject(error)
});

export default client;