import React, { useState, useCallback, useEffect, useRef } from 'react'
import {ReactComponent as Chevron} from '../../../svgs/chevron-left.svg';
import moment from 'moment';
import _ from 'lodash'
import {motion, AnimatePresence } from 'framer-motion';
import { useDebounce } from '../../../../../lib/hooks/useDebounce';

const variantsLeft = {
    hidden: { opacity: 0, x: 100, y: 0, position: 'absolute', width: '100%' },
    enter: { opacity: 1, x: 0, y: 0,  position: 'relative', width: '100%' },
    exit: { opacity: 0, x: -100, y: 0,  position: 'absolute', width: '100%' },
}


const variantsRight = {
    hidden: { opacity: 0, x: -100, y: 0, position: 'absolute', width: '100%' },
    enter: { opacity: 1, x: 0, y: 0,  position: 'relative', width: '100%' },
    exit: { opacity: 0, x: 100, y: 0,  position: 'absolute', width: '100%' },
  }
const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

const YearPicker = ({active, close, show, setShow, blockFuture, blockPast}) => {

    const ref = useRef();

    const [left, setLeft] = useState();

    const checkClick = ev => {
        if(!ref || !ref.current) return;
        if(!ref.current.contains(ev.target)) close();
    }

    useEffect(()=>{
        if(!active) return;
        window.addEventListener('click', checkClick);
        return () => {
            window.removeEventListener('click', checkClick);
        }
    }, [active])

    const [year, setYear] = useDebounce(1);

    const backYear = () => {
        setLeft(true);
        setYear(parseInt(year) - 1);
    }

    const forwardYear = () => {
        setLeft(false);
        setYear(parseInt(year) + 1);
    }

    const setMonth = useCallback(toSet => {
        console.log('setting', year, toSet)
        const newShow = _.cloneDeep(show)
        setShow(newShow.year(year).month(toSet))
        close();
    }, [year, setShow])

    useEffect(()=>{
        setYear(moment(show).format('YYYY'))
    }, [])

    console.log('yearpick', year)

    return (
        <div className={`year-picker z-10${active ? ' active' : ''}`} ref={ref}>
            <div>
                <div className="year-picker-title">
                    <button onClick={backYear}>
                        <Chevron />
                    </button>
                    <span className="year-title">{year}</span>
                    <button>
                        <Chevron onClick={forwardYear} style={{transform: 'scaleX(-1)'}}/>

                    </button>
                </div>
                <div className="relative">
                    <AnimatePresence>
                        <motion.ul
                            initial="hidden" // Set the initial state to variants.hidden
                            animate="enter" // Animated state to variants.enter
                            exit="exit"
                            variants={!left ? variantsLeft : variantsRight}
                            transition={{ type: 'linear', duration: .2 }}
                            key={year}
                            className=""
                        >
                            {months.map((month, i) => {
                                const blocked = blockFuture && (moment(`${year}-${i + 1}`).format('YYYYMM') > moment().format('YYYYMM'));

                                return (
                                    <li key={`month-${i}-${month}`} className={blocked ? 'blocked' : ''}>
                                        <span onClick={() => setMonth(i)}>
                                            {month}
                                        </span>
                                    </li>   
                                )
                            })}
                        </motion.ul>
                    </AnimatePresence>
                </div>
        </div>
    </div>
    )
}
export default YearPicker