import {rateHandler} from './rate-handler';
import WebAPI from "./api-calls";

require('query-string');

export const getTransactions = (page, year, perPage) => {
    const task = async (res, rej) => {
        if (!page) page = 1;
        if (!perPage) perPage = 20;

        const request = {
            page,
            perPage,
            year
        };

        if (year) request.year = year;

        const webApi = new WebAPI();
        const url = webApi.getEndpointWithQuerystringPayload('/api/account-transactions', request);
        await webApi.getFromWebApiWithAccessTokenAsync(url)
            .then(resp => {
                if (!resp) {
                    return res({
                        success: false,
                        request,
                        response: ''
                    });
                }
                return res({success: true, data: resp.data});
            })
            .catch(err => {
                console.log('listener:', err.response?.status, err);
                rej(err);
            });
    };

    return rateHandler(task);
}


export const getBills = (page, year, perPage) => {
    const task = async (res, rej) => {
        if (!page) page = 1;
        if (!perPage) perPage = 20;

        const request = {
            page,
            perPage,
            year
        }

        if (year) request.year = year;

        let webApi = new WebAPI();

        const url = webApi.getEndpointWithQuerystringPayload('/api/account-bills', request);
        await webApi.getFromWebApiWithAccessTokenAsync(url)
            .then(resp => {
                if (!resp) {
                    return res({
                        success: false,
                        request,
                        response: ''
                    });
                }
                return res({success: true, data: resp.data});
            })
            .catch(err => {
                console.log('listener:', err.response?.status, err);
                rej(err);
            });
    }
    return rateHandler(task);
}
