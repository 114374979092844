import React from 'react';
import InputButton from '../../Shared/FormComponents/InputButton';
import {ReactComponent as Cross} from '../../Shared/svgs/cross.svg'
import { Link, useHistory } from 'react-router-dom';


const CheckContactEmail = ({crossLink, title, buttonText, buttonLink, children}) => {
    const history = useHistory();


    return (
                <div className="bg-white rounded-2xl p-32px max-w-600px max-w-full mx-auto">
                    {crossLink ? (
                        <div className="flex">
                            <Link to={crossLink} className="w-16px ml-auto flex svg"><Cross /></Link>
                        </div>
                    ) : null}
                    <h2 className={`text-everflow-magenta text-center ${crossLink ? 'mt-20px' : ''}`}>{title}</h2>
                    <p className="text-everflow-black pt-30px text-center">{children}</p>
                    {buttonLink ? (
                        <div className="text-center pt-30px text-everflow-black">
                            <InputButton
                                onClick={()=>history.push(buttonLink)}
                                label={buttonText}
                                fitWidth
                                className="m-auto"
                            />
                        </div>
                     ) : null}
                </div>

    )
}

export default CheckContactEmail;