import React, { useCallback, useEffect, useRef, useState } from 'react';

const loadTime = 6;
const finishTime = 3;
var percent = 0;
var interval;

const InputButton = ({label, id, onClick, className, selected, disabled, error, loading, fitWidth}) => {

    const ref = useRef();

    const preventProxy = ev => {
        ev.preventDefault()
        if (window && window.mixpanel && window.mixpanel.track && id) {
            window.mixpanel.track("Button Click", { button: id })
        }
        onClick && onClick(ev);
    }

    console.log('loading', loading)

    const animate = () => {
        const anim = ref && ref.current;

        if(!anim) return;

        const animating = anim.classList.contains('loading');

        if(loading && !animating){
            // start animation
            console.log('loading animation start')
            anim.style.setProperty('--load-time', loadTime + 's')
            anim.classList.add('loading');
            interval = setInterval(()=>{
                percent += 1;
                if(percent >= 100){
                percent = 100;
                clearInterval(interval);
                }
            }, loadTime * 1000 / 50)
            return;
        }

        if(!loading && animating){
            // stop animation
            anim.style.setProperty('--finish-delay', '-' + (finishTime / 100 * percent) + 's')
            anim.style.setProperty('--finish-time', finishTime + 's')
            clearInterval(interval);
            anim.classList.add('finish');
            setTimeout(()=>{
                // reset animation
                anim.classList.remove('loading');
                anim.classList.remove('finish');
                percent = 0;
            }, 1000)
            return
        }
    };


    useEffect(()=>{
        animate();
      
    }, [loading])
    
    return (
        <div className={`${fitWidth ? '' : 'w-full'} inline-block`}>
            <div ref={ref} className={`input-button${className ? ' ' + className : ''}`}>
                <button id={id} className={`${fitWidth ? 'px-25px' : 'w-full'}`}
                    disabled={disabled || loading}
                    onClick={preventProxy}
                >
                    {label}
                </button>
                <div className="border"></div>
            </div>
            {error ? (
                    <div className="px-28px py-10px text-everflow-magenta text-13px">{error}</div>
            ) : null}
        </div>
    )
}

export default InputButton;
