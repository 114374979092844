import {getAccountNumber} from './localstorage';
import {rateHandler} from './rate-handler';
import WebAPI from "./api-calls";


export const fetchCustomerDetails = () => {
    const task = async (res, rej) => {
        try {
            const contact = getCustomerDetails();
            const payment = getPaymentDetails();
            const billing = getBillingDetails();
            const accountNumber = getAccountNumber();
            const [contactResp, paymentResp, billingResp] = await Promise.all([contact, payment, billing]);

                const response = {
                    overview: {
                        accountNumber: accountNumber,
                        companyName: contactResp.data.data.companyName,
                        contactName: contactResp.data.data.contactName,
                        billingAddress: {
                            address1: contactResp.data.data.billingAddressLine1,
                            address2: contactResp.data.data.billingAddressLine2,
                            address3: contactResp.data.data.billingAddressLine3,
                            townOrCity: contactResp.data.data.billingTownOrCity,
                            county: contactResp.data.data.billingCounty,
                            postcode: contactResp.data.data.billingPostcode
                        }
                    },
                    contact: {
                        contactEmail: contactResp.data.data.contactEmail,
                        billingEmail: contactResp.data.data.billingEmail,
                        contactPhone: contactResp.data.data.contactNumber,
                        mobilePhone: contactResp.data.data.mobileNumber,
                        otherMobilePhone: contactResp.data.data.otherMobileNumber
                    },
                    accountBalance: {
                        accountBalance: billingResp.data.data.balance,
                        expectedBilling: billingResp.data.data.billingDay,
                        paymentMethod: billingResp.data.data.paymentMethodName,
                        billingTerms: billingResp.data.data.terms
                    },
                    payment: {
                        bankAccount: paymentResp.data.data.accountNumberLastThree,
                        bankSort: paymentResp.data.data.sortCodeLastTwo,
                        contractStart: paymentResp.data.data.contractStartDate,
                        contractEnd: paymentResp.data.data.contractEndDate
                    }
                }
                return res(response);

        } catch (err) {
            return rej(err);
        }
    }
    return rateHandler(task);
}

export const getCustomerDetails = () => {
        const task = async (res, rej) => {

            const webApi = new WebAPI();
            await webApi.getFromWebApiWithAccessTokenAsync('/api/account-customer-details')
                .then(resp => {
                    if (!resp) {
                        return res({success: false});
                    }
                    return res({success: true, data: resp.data})
                })
                .catch(err => {
                    console.log('listener:', err.response.status, err);
                    rej(err);
                });
        }
        return rateHandler(task);
}

export const getPaymentDetails = () => {
        const task = async (res, rej) => {
            const webApi = new WebAPI();
            await webApi.getFromWebApiWithAccessTokenAsync('/api/account-customer-payment')
                .then(resp => {
                    if (!resp) {
                        return res({success: false});
                    }
                    return res({success: true, data: resp.data})
                })
                .catch(err => {
                    console.log('listener:', err.response.status, err);
                    rej(err);
                });
        }
    return rateHandler(task);
}

export const getBillingDetails = () => {
    const task = async (res, rej) => {

        const webApi = new WebAPI();
        await webApi.getFromWebApiWithAccessTokenAsync('/api/account-customer-billing')
            .then(resp => {
                if (!resp) {
                    return res({success: false});
                }
                return res({success: true, data: resp.data})
            })
            .catch(err => {
                console.log('listener:', err.response.status, err);
                rej(err);
            });
    }
    return rateHandler(task);
}

export const updateCustomerDetails = async (data) => {
    const task = async (res, rej) => {
        const webApi = new WebAPI();        
        await webApi.postToWebApiWithAccessTokenAsync('/api/account-customer-update', data)
            .then(resp => {
                if (!resp) {
                    return res({success: false});
                }
                return res({success: true, data: resp.data})
            })
            .catch(err => {
                console.log('listener:', err.response.status, err);
                rej(err);
            });
    }
    return rateHandler(task);
}
