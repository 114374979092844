import React, { useCallback, useState } from 'react';
import { ReactComponent as Logo } from '../../Shared/svgs/logo.svg'
import { ReactComponent as SocialInstaIcon } from '../../Shared/svgs/brand-instagram.svg';
import { ReactComponent as SocialFbIcon } from '../../Shared/svgs/brand-facebook.svg';
import { ReactComponent as SocialXIcon } from '../../Shared/svgs/brand-x.svg';
import { ReactComponent as SocialLnkdInIcon } from '../../Shared/svgs/brand-linkedin.svg';
import { ReactComponent as Person } from '../../Shared/svgs/person.svg'
import { ReactComponent as QuestionMark } from '../../Shared/svgs/question-mark.svg'
import { ReactComponent as LogOut } from '../../Shared/svgs/export.svg'

import { Link } from 'react-router-dom';
import { logout } from '../../../lib/api/user'
import { useHistory } from "react-router";
import { useLogin } from '../../../lib/hooks/useLogin';

const DashboardWrapper = ({ children, helpLink }) => {
    const loggedIn = useLogin();
    const [mobileExpanded, setMobileExpanded] = useState(false);

    const user = loggedIn && loggedIn.account && loggedIn.account.token;

    const history = useHistory()
    const logoutUser = useCallback(()=>{
        logout().then(()=>{
            if (window?.mixpanel?.track) {
                window.mixpanel.track("User Logged Out")
            }
            history.push('/login');
        })
    }, [history])

    const onNavigate = () => {
        if (mobileExpanded) {
            setMobileExpanded(false)
        }
    }

    return (
        <div id="dashboard-wrapper" className="flex flex-col min-h-screen">
            <div id="dashboard-header" className="bg-everflow-magenta">
                <div className="wrapper items-center">
                    <Link id="navbarlogo" to="/">
                        <Logo id="icon-logo-everflow" height='30' />
                    </Link>
                    <div id="navbarLinks" className={`items-start md:items-center text-everflow-white ${mobileExpanded ? 'flex' : 'hidden'} md:flex flex-col md:flex-row gap-20px justify-items-end pb-4 pt-8 md:py-0`}>
                        {user && (
                            <span id="navbarAccountNo" className="ml-auto text-everflow-white font-bold mr-38px hidden md:inline">{loggedIn.account.accountNumber}</span>
                        )}
                        <a id="navbarHelpLink" onClick={() => {
                            if (window?.mixpanel?.track) {
                                window.mixpanel.track("Help link visited");
                            }
                            onNavigate()
                        }} href={helpLink} target="_blank" className="pb-3px flex gap-4"><QuestionMark id="icon-questionmark" className="w-28px m-auto" /><span className="inline md:hidden"> Help Centre</span></a>
                        {user ? (
                            <>
                                <Link onClick={onNavigate} id="navbarCustomerDash" to="/dashboard/customer" className="pb-3px flex gap-4"><Person id="icon-person" className="w-28px m-auto" /> <span className="inline md:hidden"> My Account ({loggedIn.account.accountNumber})</span></Link>
                                <a onClick={logoutUser} className='pb-3px flex gap-4'><LogOut id="navbarLogOut" className="w-28px m-auto cursor-pointer" /> <span className='flex md:hidden'>Logout</span></a>
                            </>
                        ) : null}
                    </div>
                    <div id="NavbarMobileMenuToggle" className='flex md:hidden'>
                        <img className='ml-auto' src={mobileExpanded ? '/assets/icons/ev-hamburger-x.svg' : '/assets/icons/ev-hamburger-menu.svg'} onClick={() => setMobileExpanded(!mobileExpanded)} />
                    </div>
                </div>
            </div>
            <div id="dashboard-body" className="flex-grow flex">
                <div className="m-auto flex flex-col justify-center h-full w-full">
                    {children}
                </div>
            </div>
            <div id="dashboard-footer" className="bg-everflow-magenta" >
                <div className="max-w-450px m-auto grid grid-cols-1 justify-items-center text-white font-bold sm:flex-row sm:justify-between sm:p-10 sm:py-15px py-15px">
                    <a id="footer-link-legal" target="_blank" rel="noreferrer" href="https://everflowutilities.com/legal" className="altform-bold-white-21px">Legal</a>
                </div>
                <div className="max-w-200px md:max-w-450px m-auto grid grid-cols-4 justify-items-center text-white sm:flex-row sm:justify-between sm:p-10 sm:py-15px py-15px">
                    <a id="footer-link-instagram" onClick={() => { if (window?.mixpanel?.track) { window.mixpanel.track("Social Link Visit", { link: "Instagram" })} }} href="https://www.instagram.com/everflowutilities/" className="" target="_blank" rel="noreferrer"><SocialInstaIcon id="icon-brand-instagram" className="h-32px" /></a>
                    <a id="footer-link-facebook" onClick={() => { if (window?.mixpanel?.track) { window.mixpanel.track("Social Link Visit", { link: "Facebook" }) } }} href="https://www.facebook.com/EverflowUtilities" className="" target="_blank" rel="noreferrer"><SocialFbIcon id="icon-brand-facebook" className="h-32px" /></a>
                    <a id="footer-link-x" onClick={() => { if (window?.mixpanel?.track) { window.mixpanel.track("Social Link Visit", { link: "X" }) } }} href="https://twitter.com/Everflow_util" className="" target="_blank" rel="noreferrer"><SocialXIcon id="icon-brand-x" className="h-32px" /></a>
                    <a id="footer-link-linkedIn" onClick={() => { if (window?.mixpanel?.track) { window.mixpanel.track("Social Link Visit", { link: "LinkedIn" }) } }} href="https://www.linkedin.com/company/10409528" className="" target="_blank" rel="noreferrer"><SocialLnkdInIcon id="icon-brand-linkedIn" className="h-32px" /></a>
                </div>
            </div>
        </div>
    )
}

export default DashboardWrapper
