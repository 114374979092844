import moment from 'moment';

export const checkMaintenance = () => {
    const maintenance = process.env.REACT_APP_MAINTENANCE
    if(!maintenance) return false;

    const [start, end] = maintenance.split('-').map(i => {
        let time = i.split(':');
        if (time.length === 1) time.push(0)

        let outputTime = moment().hour(time[0]).minutes(time[1])

        if (moment().isDST()) {
            outputTime = outputTime.add(1, 'h')
            console.log(outputTime)
        }
        return outputTime.format('HHmm')
    });

    if(start === end) return false;
    
    console.log('es6')
    console.log('Original Maintenance Times', maintenance, 'Actual start', start, 'Actual end', end);

    var ooo = false;
    const current = moment.utc().format('HHmm');

    console.log('current', current)

    if(start < end){
        console.log('start less than')
        if(current >= start && current < end) ooo = true;
    }else{
        console.log('start overnight')
        if(current >= start || current < end) ooo = true;
    }

    console.log('under maintenance ', ooo)
    return ooo;
}