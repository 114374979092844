import React, { useCallback, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import InputButton from '../FormComponents/InputButton'

const PopUp = () => {
    const modal = document.getElementById('modal');
    const [active, setActive] = useState()
    const [countdown, setCountdown] = useCountdown();
    const [status, setStatus] = useState();
    const [attempt, setAttempt] = useState({});
    const [close, setClose] = useState();
    const [failed, setFailed] = useState();

    const eventHandler = useCallback((event)=>{
        switch(event.detail.action){
            case 'show':
                setActive(true);
                break;
            case 'startTimer':
                setCountdown(event.detail.data);
                break;
            case 'requesting':
                setStatus('Retrying request')
                setAttempt(event.detail.data)
                break;
            case 'hide':
                setActive(false);
                break;
            case 'failed':
                setFailed(true)
                break;
        }
    }, [])

    useEffect(()=>{
        if(!active) setFailed(false);
    }, [active])


    useEffect(()=>{
        if(typeof window === 'undefined') return

        window.addEventListener('retryMessage', eventHandler);

        return () => window.removeEventListener('retryMessage', eventHandler)
        
    }, [])

    if(!active) return null;

    const Box = (
        <div className="fixed top-0 left-0 w-full h-full z-20" style={{background: 'rgba(0,0,0,.5)'}}>
            <div className="p-20px w-full max-w-600px absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {!failed ? (
                <div className="rounded-2xl bg-white p-40px">
                    <h2 className="text-everflow-magenta text-center">We are currently experiencing a high volume of users, please wait.<br />Thank You!</h2>
                    <div className="h-60px flex items-center justify-center">
                        {countdown ? (
                            <div className="mt-20px text-center text-gray-500">
                                Attempting to reconnect in {countdown} second{countdown !== 1 ? 's' : ''}
                            </div>
                        ) : (
                            <div className="mt-20px text-center text-gray-500 flex justify-center items-center">
                                <LoadingCircle />
                                <div className="ml-10px">
                                    Attempting to reconnect, try {attempt.try} of {attempt.of}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            ) : (
                <div className="rounded-2xl bg-white p-40px">
                    <h2 className="text-everflow-magenta text-center">We are currently experiencing a high volume of users, please check back soon to complete your request.</h2>
                    <div className="mt-40px text-center">
                        <div className="max-w-200px w-full inline-block">
                            <InputButton
                                label="Back to site"
                                onClick={()=>setActive(false)}
                            />
                        </div>
                    </div>
                </div>
            )}

            </div>
        </div>
    )

    return ReactDOM.createPortal(
        Box,
        modal
    )


}

export const useCountdown = (secs) => {
    const [timer, setTimer] = useState(secs);
  
    useEffect(() => {
      if(!timer) return;
      var timeout = setTimeout(()=> {
        setTimer(timer - 1);
      }, 1000)
      return () => {
        clearTimeout(timeout)
      }
    }, [timer])
  
    return [timer, setTimer];
  }
  


const LoadingCircle = () => {
    const ref = useRef();

    const [length, setLength] = useState();


    useEffect(()=>{

        const circle = ref.current;
        const initLength = circle.getTotalLength()

        circle.style.strokeDasharray = initLength;
        circle.style.strokeDashoffset = initLength;
        circle.style.transform = 'rotate(-180 26.25 26.25)';

        setTimeout(()=>{
            circle.style.transition = 'all 3s linear';
            circle.style.strokeDashoffset = 0;

        }, 500)

    }, [])


    console.log('circledebug', length)

    return (
        <svg className="w-40px text-everflow-green" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 53 53">
            <circle ref={ref} className="stroke-current" style={{fill: 'transparent', strokeWidth: '2px'}} cx="26.2" cy="26.2" r="24.8" transform="rotate(-180 26.25 26.25)"/>
        </svg>
    )
}




export default PopUp