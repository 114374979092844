import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';
import CreateForm from './CreateForm';
import { useRouteMatch } from 'react-router';

const LoginBox = () => {
    const [tab, setTab] = useState('login');

    const match = useRouteMatch('/login/:section?/:param?')
    const section = match && match.params && match.params.section

    useEffect(() => {
        if (!section && tab !== 'login') return setTab('login');
        if (section && section !== tab) return setTab(section);
    }, [section])


    return (
        <div className="relative w-full flex justify-center">
            <div className="w-full max-w-450px m-auto z-10">
                <div className="bg-white w-full rounded-t-2xl rounded-b-2xl">
                    <div className="p-20px sm:p-20px">
                        {section === 'create' ? (
                            <div>
                                <p className="text-everflow-black mt-4 mb-2 text-22px text-center">Register for the</p>
                                <p className="text-24px text-center text-everflow-magenta font-bold">Everflow Customer Portal</p>
                            </div>
                        ) : (
                                <div>
                                    <p className="text-everflow-black mt-4 mb-2 text-22px text-center">Welcome to the</p>
                                    <p className="text-24px text-center text-everflow-magenta font-bold">Everflow Customer Portal</p>
                            </div>
                        )
                        }
                    </div>
                    <div className="p-40px sm:p-60px">
                        {tab == 'login' ? (
                            <LoginForm />
                        ) : null}
                        {tab == 'create' ? (
                            <CreateForm />
                        ) : null}
                    </div>
                    <div className="p-32px text-center border-t-2 border-everflow-magenta">
                        {section === 'create' ? (
                            <div>
                                <Link className="underline text-everflow-black" to="/">Return to Login</Link>
                            </div>
                        ) : (
                            <div>
                                <Link className="underline text-everflow-black" to="/login/forgot">Forgotten your password?</Link><br /><br />
                                <Link className="underline text-everflow-magenta font-bold" to="/login/create">Not registered? Create your account here</Link>
                            </div>
                        )
                        }
                    </div>
                    <div className="p-10px text-center">
                        This site is protected by reCAPTCHA and the Google <a className="underline" href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a className="underline" href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</a>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default LoginBox