import DeviceAndBrowserDetector from './deviceandbrowserdetector';
import { rateHandler } from './rate-handler';
import WebAPI from "./api-calls";

class DocumentDownloader {

    downloadDocumentUsingBase64 = async (webDocument, id) => {
        if (!webDocument || !id) {
            return null;
        }

        // need some defensive stuff in here so if no access token, the logout event occurs.
        await this.#getDocumentFromAPI(id)
            .then(resp => {
                if (!resp) {
                    return null;
                }

                const docDetails = this.#getDocDetails(resp.headers);
                if (!docDetails) {
                    return null;
                }

                const link = webDocument.createElement('a');
                link.setAttribute('id', 'downloadableDoc');
                this.#downloadForBrowserType(link, docDetails, resp.data);
                link.click();
            })
            .catch(err => {
                alert('Unable to find document.');
            });
    }


    #downloadForBrowserType = (link, docDetails, docDataBase64) => {

        const browserAndDevice = new DeviceAndBrowserDetector();
        if (browserAndDevice.productName.toLowerCase() === "safari") {
            this.#createLinkForSafari(link, docDetails.fileName, docDetails.contentType, docDataBase64);
        }
        else {
            this.#createLinkForOtherBrowsers(link, docDetails.contentType, docDataBase64, docDetails.fileName);
        }
    }

    #createLinkForOtherBrowsers = (link, docContentType, docDataBase64, docFileName) => {
        link.href = 'data:' + docContentType + ';base64,' + docDataBase64;
        link.setAttribute(
            'download',
            docFileName,
        );
    }

    #createLinkForSafari = (link, docFileName, docContentType, docDataBase64) => {
        const bytArys = this.#getByteArraysForDocBlob(docDataBase64);
        const blob = this.#getDocAsBlob(bytArys, docContentType);
        const url = URL.createObjectURL(blob);

        link.href = url
        link.setAttribute(
            'download',
            docFileName,
        );
    }

    #getDocAsBlob = (bytArys, docContentType) => {

        return new Blob(bytArys, { type: (docContentType.toLowerCase() === "application/pdf") ? "application/octet-stream" : docContentType });
    }

    #getByteArraysForDocBlob = (docDataBase64) => {
        const byts = atob(docDataBase64);
        const byteArrays = [];

        for (let offset = 0; offset < byts.length; offset += 512) {
            const slice = byts.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return byteArrays;
    }

    #getDocumentFromAPI = (id) => {
        const task = async (res, rej) => {

            const webApi = new WebAPI();
            const url = webApi.getEndpointWithQuerystringPayload('/api/account-download', { docId:id })
            await webApi.getFromWebApiWithAccessTokenAsync(url)
                .then(resp => {
                    if (!resp) {
                        return res({ success: false });
                    }
                    return res(resp);
                })
                .catch(err => {
                    console.log(err);
                    return rej(err);
                })
        }
        return rateHandler(task);
    }

    #getDocDetails = (hdrs) => {
        if (!hdrs) {
            return null;
        }

        const fileName = this.#getFileNameFromHeader(hdrs['content-disposition']);
        if (!fileName) {
            return null;
        }

        const contentType = hdrs['content-type'];
        if (!contentType) {
            return null;
        }

        return { contentType, fileName }
    }

    #getFileNameFromHeader = (disposition) => {

        if (!disposition) {
            return null;
        }
        if (!disposition.startsWith('attachment')) {
            return null;
        }

        return disposition.split(';')[1].split('=')[1];
    }
}

export default DocumentDownloader;