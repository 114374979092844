import { InformationLog, WarningLog } from '../../components/Shared/ServerLogging';
import { useEffect, useState } from 'react';

export const useConfig = (path) => {
    const [config, setConfig] = useState();

    const fetchData = async (path, signal) => {
        const response = await fetch(`/api/Configuration/${path}`, { signal: signal });
        return response.json();
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        fetchData(path, signal)
            .then(data => {
                setConfig(data);
                InformationLog(`Completed Action: Reading server configuration, [${path}]`);
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    WarningLog(`Aborted Action: Reading server configuration, [${path}]`);
                }
                else {
                    WarningLog(`Error reading server configuration, [${path}]:`, err);
                }
            });

        return () => {
            controller.abort();
        }

    }, [path]);

    return config;
};

export default useConfig;