import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { InformationLog } from '../../../Shared/ServerLogging';

const ThankYou = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const merchantReference = searchParams.get('id');

    InformationLog(`Thank you page hit for merchantReference: ${merchantReference}`);

    useEffect(() => {
        window.top.scrollTo(0, 0)
    }, [])

    return (
        <>{
            <div id="cardpayment-submitted-wrapper" className="grid grid-cols-1 pt-20px pb-20px min-h-screen bg-everflow-white">
                <div className="flex flex-col p-4 text-center text-everflow-black">
                    <p>Thank you for submitting your details for payment.</p>
                    <p>If your payment was successful, you will receive a confirmation email shortly and your payment will be applied to your account by close of business the following working day.  Please do not attempt to process the payment again during this time as you may be charged twice.</p>
                    <p className="pt-10px">
                        <strong>
                            <a
                                href="/"
                                target="_parent"
                                className="m-auto w-full mt-40px sm:w-200px h-50px bg-everflow-magenta rounded-25px box-border border-2 border-white text-everflow-white flex justify-center items-center">
                                Back to your dashboard
                            </a>
                        </strong>
                    </p>
                </div>
            </div>
        }</>
    );
}

export default ThankYou