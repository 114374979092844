import React, { useState } from 'react';
import { useDebounce } from '../../../../lib/hooks/useDebounce';
import {ReactComponent as Cross} from '../../../Shared/svgs/cross.svg'
import {ReactComponent as Tick} from '../../../Shared/svgs/tick.svg'
import TickAnim from '../../../Shared/TickAnim';

const InputText = ({ onChange, value, label, labelFor, name, password, placeholder, className, readOnly, tick, cross, req, error, id, onBlur, onFocus, loadingTick, thin, maxLength }) => {

    const [active, setActive] = useDebounce(100);
    
    const focus = active || !!value;

    const classes = [
        'input-text'
    ]

    if(className) classes.push(className);
    if(focus) classes.push('focus');

    if(tick) classes.push('tick');
    if(error || cross) classes.push('cross');

    return (
        <div className={classes.join(' ')}>
            <div className="input-text-wrapper">
                {label ? (
                    <label htmlFor={labelFor}>{label}{req ? (<span className="text-everflow-magenta"> *</span>) : null}</label>
                ) : null}
                {tick ? (
                    <div className="absolute top-1/2 right-28px transform -translate-y-1/2 flex">
                        <Tick className="w-16px"/>
                    </div>
                ) : null}
                {loadingTick ? (
                    <div className="absolute top-1/2 right-10px transform -translate-y-1/2 flex">
                        <TickAnim size="40px"/>
                    </div>
                ) : null}
                <input
                    style={thin ? { height: '50px' } : {}}
                    id={id}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    value={value || ''}
                    type={password ? 'password' : 'text'}
                    onFocus={ev => { setActive(true); onFocus && onFocus(ev) }}
                    onBlur={ev => { setActive(false); onBlur && onBlur(ev) }}
                    readOnly={!!readOnly}
                    className={className}
                    maxLength={maxLength}
                />
            </div>
            {error ? (
                <div className="px-28px py-10px text-everflow-magenta text-13px">{error}</div>
            ) : null}
        </div>
    )
}



export default InputText