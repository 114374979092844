import React, {useState} from 'react';
import LoginBox from './LoginBox';
import LoginWrapper from '../../Shared/LoginWrapper';
import {
    useLocation
  } from "react-router-dom";

import { Route } from 'react-router';
import CreatePassBox from '../CreatePass/CreatePassBox';
import ResetPassConfirm from './ResetPasswordConfirm'
import ForgotForm from '../ForgotPassword/ForgotForm'
import CheckContactEmail from '../CheckContactEmail';
import CustomSwitch from '../../Shared/RouteSwitch'

const Login = () => {

    const location = useLocation();

    return (
        <>
        <LoginWrapper>
            <CustomSwitch location={location} >
                <Route exact path='/login/forgot'>
                    <div className="z-10 w-full min-h-700px">
                        <ForgotForm />
                    </div>
                </Route>
                <Route exact path='/login/forgot/check-email'>
                    <div className="z-10 w-full">
                        <CheckContactEmail
                            crossLink="/login"
                            title="An email is on its way!"
                            buttonText="Return to homepage"
                            buttonLink="/login"
                        >
                            We’ve sent you an email with a link to reset your password.<br />Please check the inbox of the contact email linked to your account. 
                        </CheckContactEmail>
                    </div>
                </Route>
                <Route exact path='/login/forgot/confirm'>
                    <div className="z-10 w-full min-h-700px">
                        <ResetPassConfirm />
                    </div>
                </Route>
                <Route exact path='/login/confirm'>
                    <div className="z-10 w-full min-h-700px">
                        <CreatePassBox />
                    </div>
                </Route>
                <Route exact path='/login/check-email'>
                    <div className="z-10 w-full">
                        <CheckContactEmail
                            crossLink="/login"
                            title="An email is on its way!"
                            buttonText="Return to homepage"
                            buttonLink="/login"
                        >
                            We’ve sent you an email with a link to set your password.<br />Please check the inbox of the contact email linked to your account.

                        </CheckContactEmail>
                    </div>
                </Route>
                <Route path="/login">
                    <div className="z-10 w-full min-h-700px">
                        <LoginBox />
                    </div>
                </Route>
            </CustomSwitch>
        </LoginWrapper>
        </>
    )
}


export default Login;