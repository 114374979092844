import React, {useCallback, useRef, useState} from "react";
import InputText from "../../../../Shared/FormComponents/InputText";
import InputButton from "../../../../Shared/FormComponents/InputButton";
import {tryLogin} from '../../../../../lib/api/user';
import {stripUsernameChars} from '../../../../Shared/StringManipulation';
import {useHistory} from "react-router";
import * as Yup from 'yup';
import {useLocation} from "react-router-dom";
import Form from "../../../../Shared/Form";
import ReCAPTCHA from "react-google-recaptcha";


const LoginForm = () => {

    const location = useLocation();

    const recaptchaRef = useRef()

    const[loading, setLoading] = useState();
    const [globalErr, setGlobalErr] = useState();

    const expired = location && location.search.indexOf('session-expired') > -1;

    const history = useHistory();
    const loginUser = useCallback(async (account, password, setErrors, setFormStatus)=> {

        var recaptchaValue;
        
        setLoading(true);
        await tryLogin(account.trim(), password, recaptchaValue)
            .then(()=>{
                setLoading(false);
                setTimeout(() => {
                    history.push('/dashboard/home');
                }, 500);
            })
            .catch(err => {
                // error handle
                recaptchaRef.current.reset();
                console.log('loginpage error', err);
                setLoading(false);
                if(!err) setFormStatus('An unexpected error has occurred.')
                if(err.formStatus) setFormStatus(err.formStatus)
            })
    }, [setLoading, setGlobalErr, history])

    return (
        <Form
            initialValues={{account: '', password: ''}}
            validationSchema={formSchema}
            className="grid grid-cols-1 gap-y-24px justify-items-center"
            onSubmit={({account, password}, {setErrors, setStatus}) => loginUser(account, password, setErrors, setStatus)}
        >
            {({values, setFieldValue, handleSubmit, errors, status, validateField, handleChange, handleBlur, touched}) => {
                return(
                    <>
                        {expired ? (
                            <div className="text-everflow-magenta text-center">Your session has expired, please log in.</div>
                        ) : null}
                        {status ? (
                            <div className="text-everflow-magenta">{status}</div>
                        ) : null}
                        <InputText
                            className="w-full rounded-28px"
                            label="Account number"
                            value={values.account}
                            id="account"
                            onChange={ev => stripUsernameChars(ev.target.value, val => setFieldValue('account', val))}
                            error={touched && touched.account && errors && errors.account}
                            onBlur={handleBlur}
                            placeholder="e.g EFW000000-00"
                        />
                        <InputText
                            className="w-full rounded-28px"
                            label="Password"
                            value={values.password}
                            id="password"
                            onChange={handleChange}
                            error={touched && touched.password && errors && errors.password}
                            onBlur={handleBlur}
                            password
                        />
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={process.env.REACT_APP_CAPTCHA}
                            badge="bottomleft"
                        />
                        <InputButton
                            loading={loading}
                            className="w-full"
                            label="Log in"
                            onClick={handleSubmit}
                        />

                    </>
                )
            }}
        </Form>
    )
}

const formSchema = Yup.object().shape({
    account: Yup.string().required('Please enter your account number'),
    password: Yup.string().required('Please enter your password')
})

export default LoginForm