import React from 'react';
import DashboardWrapper from '../../Shared/DashboardWrapper'

const NotFound = () => {
    return (
        <DashboardWrapper>
            <div className="wrapper">
                <h1 className="text-bold text-50px text-blue-default-500 text-center">404</h1>
                <h3 className="text-center mt-20px">Page not found</h3>
            </div>
        </DashboardWrapper>
    )
}

export default NotFound;