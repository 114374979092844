/**
 * User functions
 *
 */
import {rateHandler} from './rate-handler';
import WebAPI from "./api-calls";
import {setLocalStorage} from "./localstorage";
import {getCustomerDetails} from "./customer-details";

export const tryLogin = (username, password, captcha) => {
    const task = async (res, rej) => {

        try{
            const loginResp = await login(username, password, captcha);
            const contact = await getCustomerDetails();

            const account = {
                token: loginResp.token,
                accountNumber: username,
                name: contact.data.data.contactName
            }

            setLocalStorage(account.token, account.accountNumber, account.name)

            return res(account);
        }catch (err) {
            return rej(err);
        }
    }

    return rateHandler(task);
}

export const login = async (username, password, captcha) => {
    const task = async (res, rej) => {
        if (!username || !password) {
            const error = {success: false, form: {}}

            if (!username) error.form.account = 'Please enter your account number';
            if (!password) error.form.password = 'Please enter your password';

            return rej(error);
        }

        let webApi = new WebAPI();

        await webApi.postToWebApi('/api/account-login', {username, password, captcha})
            .then(resp => {
                console.log('resp', resp.data)

                const account = {
                    token: resp.data.access_token,
                    accountNumber: username,
                }

                setLocalStorage(account.token, account.accountNumber, "")

                return res(account)
            })
            .catch(err => {

                if (err && err.data && err.status === 429) return rej(err);
                if(!err.error){
                    err = 'An unexpected error has occurred.';
                }

                if (err && err.error) {
                    if (err.error === 'invalid_grant') {
                        err = 'Incorrect Account number or Password'
                    }
                }

                return rej({
                    success: false,
                    formStatus: err
                })
            })
    }

    return rateHandler(task);
}

export const logout = () => {
    return new Promise((res, rej) => {
        // no api as of yet
        console.log('removing keys')
        localStorage.removeItem('account');
        document.cookie = 'bearer=; Max-Age=-99999999;';
        res({
            success: true
        })
    })
}

export const createAccount = accountNumber => {
    const task = (res, rej) => {
        let webApi = new WebAPI();
        webApi.postToWebApi('/api/account-setup', {accountNumber})
            .then(resp => {
                console.log(resp);
                //Added as a temp hack, as the Auth service if the acc num is not valid still returns an OK Response, which won't hit the catch
                if (!resp.data.succeeded) {
                    rej({
                        success: false,
                        formStatus: 'Please check your Account Number',
                    })
                }
                res({success: true})
            })
            .catch(err => {
                if (err && err.response && err.response.status === 429) return rej(err);

                if (err && err.response && err.response.data) {
                    return rej({
                        success: false,
                        formStatus: err.response.data,
                    })
                }

                rej({
                    success: false,
                    formStatus: 'An error has occurred'
                })
            })
    }

    return rateHandler(task);
}

export const confirmAccount = data => {
    const task = (res, rej) => {
        let webApi = new WebAPI();
        webApi.postToWebApi('/api/account-confirm', data)
            .then(resp => {
                console.log(resp);
                if (!resp.data.succeeded) {
                    if (resp.data.errors.find(i => i.code == 'InvalidToken')) {
                        rej(new Error('Invalid Confirm Token'));
                    }
                    rej(new Error('An Error Occurred'));
                }
                res({success: true})
            })
            .catch(err => {
                if (err && err.response && err.response.status === 429)
                {
                    rej(new Error('Too Many Requests'));
                }

                if (err && err.response && err.response.data) {
                    rej(new Error('Account created, please log in.'));
                }

                rej(new Error('An error has occurred'));
            })
    }
    return rateHandler(task);
}

export const forgotPassword = accountNumber => {
    const task = async (res, rej) => {
        let webApi = new WebAPI();
        await webApi.postToWebApi('/api/account-forgot-password', JSON.stringify({accountNumber}))
            .then(resp => {
                if (!resp.data.succeeded) {
                    if (resp.data.errors.find(i => i.code == 'InvalidToken')) {
                        rej(new Error('Invalid Confirm Token'));
                    }
                    rej(new Error('An Error Occurred'));
                }
                res(resp);
            })
            .catch(err => {
                console.log('error response', err.response.data)
                if (err && err.response && err.response.status === 429) return rej(err);
                rej({
                    success: false,
                    formStatus: err && err.response && err.response.data || 'An unexpected error has occurred.'
                })
            })
    }
    return rateHandler(task);
}

export const forgotConfirmPassword = data => {
    const task = async (res, rej) => {
        const webApi = new WebAPI();

        await webApi.postToWebApi('/api/account-forgot-password-confirm', data)
            .then(resp => {
                if (!resp.data.succeeded) {
                    if (resp.data.errors.find(i => i.code == 'InvalidToken')) {
                        rej(new Error('Invalid Confirm Token'));
                    }
                    rej(new Error('An Error Occurred'));
                }

                console.log('confirmer success')
                res({success: true})
            })
            .catch(err => {
                console.log('confirmer failed', err)
                if (err && err.response && err.response.status === 429) return rej(err);
                const msg = err && err.response && err.response.data || 'An unexpected error has occurred';

                rej({
                    success: false,
                    formStatus: msg
                })
            })
    }
    return rateHandler(task);
}

export const getValidAccountNumber = accountNumber => {
    return accountNumber.replace(/[^a-zA-Z\-0-9]+/g, '');
}