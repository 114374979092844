import client from './client';
import queryString from 'query-string';
import {getAccessToken, getAccountNumber} from './localstorage';
import {rateHandler} from './rate-handler';

// class to provide wrappers allowing us to make repeatable calls to webapi endpoints, with optional payloads & methods which allow
// access-token to be included for when we need to be authenticated.  Access token must live in the localstorage on t

class WebAPI {

    getFromWebApiWithAccessTokenAsync = async (endpoint, payload) => {
        const task = async (res, rej) => {

            const bearerToken = getAccessToken();
            const accountNumber = getAccountNumber();
            if (!bearerToken) {
                const error = {success: false, form: {}}
                error.message = 'no_access_token';
                return rej(error);
            }

            const endpointWithPayload = this.getEndpointWithQuerystringPayload(endpoint, payload);

            await client.get(endpointWithPayload, {
                headers: {
                    'Authorization': bearerToken
                }
            })
                .then(resp => {
                    console.log(resp);
                    if (resp.data.hasOwnProperty('accountNumber') &&  accountNumber) {
                        resp.data.accountNumber = accountNumber;
                    }
                    return res({success: true, data: resp.data, headers: resp.headers })
                })
                .catch(error => {
                    console.log(error);
                    return rej(error);

                })
        }
        return rateHandler(task);
    }

    postToWebApi = async (endpoint, params) => {
        const task = async (res, rej) => {

            await client.post(endpoint, params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(resp => {
                    console.log(resp);
                    return res({success: true, data: resp.data, headers: resp.headers })
                })
                .catch(err => {
                    console.log(err.response.data);
                    return rej(err.response.data);
                })
        }
        return rateHandler(task);
    }

    postToWebApiWithAccessTokenAsync = async (endpoint, params) => {
        const task = async (res, rej) => {

            const bearerToken = getAccessToken();
            if (!bearerToken) {
                const error = {success: false, form: {}}
                error.message = 'no_access_token';
                return rej(error);
            }

           await client
                .post(endpoint, params, {
                    headers: {
                        'Authorization': bearerToken,
                        'Content-Type': 'application/json'
                    }
                })
                .then(resp => {
                    console.log(resp);
                    return res({success: true, data: resp.data, headers: resp.headers })
                })
                .catch(err => {
                    console.log(err.response.data);
                    return rej(err.response.data);
                })
        }
        return rateHandler(task);
    }

    getEndpointWithQuerystringPayload = (endpoint, payload) => {
        let result = endpoint;
        if (payload) {
            result += `?${queryString.stringify(payload)}`;
        }
        return result;
    }

}

export default WebAPI;

