import WebAPI from "./api-calls";
import { rateHandler } from './rate-handler';

export const getCrossSellMessage = () => {
    const task = async (res, rej) => {

        const webApi = new WebAPI();
        await webApi.getFromWebApiWithAccessTokenAsync('/api/messaging/customer-portal-message')
            .then(resp => {
                console.log(resp)
                if (!resp) {
                    return res({ success: false });
                }
                return res({ success: true, data: resp.data })
            })
            .catch(err => {
                console.log('listener:', err.response.status, err);
                rej(err);
            });
    }
    return rateHandler(task);
}