import React from 'react';
import { SkeletonInput, SkeletonText } from '../../../../Shared/Skeletons';

const Skeleton = () => {
    return (
        <div>

            <div>
            <p className="text-everflow-black mb-40px font-bold text-2xl"><SkeletonText className="w-1/5" /></p>
                <h2 className="text-everflow-black mb-40px font-bold text-2xl"><SkeletonText className="w-1/5" /></h2>
            </div>

            <div className="p-4 rounded-18px border-everflow-grey25 border-2 text-left flex flex-row justify-between mb-40px">
                <div className="flex flex-col items-start justify-around flex-1 md:flex-row md:items-center">
                    <SkeletonText className="w-1/5 pr-20px" />
                    <SkeletonText className="w-1/5 pr-20px" />
                    <SkeletonText className="w-1/5 pr-20px" />
                    <SkeletonText className="w-1/5 pr-20px" />
                </div>
            </div>

            <div>
                <div className="grid gap-30px overflow-auto">
                    <ul className="min-w-660px text-left divide-y-2 divide-gray-default-500">
                        <li>
                            <div className="py-10px flex flex-col items-start md:flex-row md:justify-center md:items-center gap-10px">
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                                    <SkeletonText className="w-full" />
                                </div>
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:flex-grow">
                                    <SkeletonText className="w-full" />
                                </div>
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                                    <SkeletonText className="w-full" />
                                </div>
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                                    <SkeletonText className="w-full" />
                                </div>
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                                    <SkeletonInput className="w-full" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="py-10px flex flex-col items-start md:flex-row md:justify-center md:items-center gap-10px">
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                                    <SkeletonText className="w-full" />
                                </div>
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:flex-grow">
                                    <SkeletonText className="w-full" />
                                </div>
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                                    <SkeletonText className="w-full" />
                                </div>
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                                    <SkeletonText className="w-full" />
                                </div>
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                                    <SkeletonInput className="w-full" />
                                </div>
                            </div>
                        </li>                     
                        <li>
                            <div className="py-10px flex flex-col items-start md:flex-row md:justify-center md:items-center gap-10px">
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                                    <SkeletonText className="w-full"/>
                                </div>
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:flex-grow">
                                    <SkeletonText className="w-full" />
                                </div>
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                                    <SkeletonText className="w-full" />
                                </div>
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                                    <SkeletonText className="w-full" />
                                </div>
                                <div className="w-100px flex flex-row mb-4 md:mb-0 flex-shrink-0 md:w-130px">
                                    <SkeletonInput className="w-full" />
                                </div>                       
                            </div>
                        </li>
                    </ul>
                </div>
            </div >
        </div>
    )
}

export default Skeleton;