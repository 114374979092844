import React, { useCallback, useEffect, useState } from 'react';
import MeterEntry from './MeterEntry';
import { useHistory, } from "react-router-dom";
import { fetchMeterData } from '../../../../../lib/api/premises';
import SkeletonPage from './Skeleton'
import moment from 'moment';
import { ReactComponent as WaterIcon } from '../../../../Shared/svgs/water-ef-magenta.svg'

const EditMeter = ({ spid }) => {

    const [pageData, setPageData] = useState();
    const [loadError, setLoadError] = useState();
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState();
    const history = useHistory();

    const loadData = useCallback(async () => {
        setLoading(true)
        await fetchMeterData(spid).then((resp) => {
            if (loadError) setLoadError(false);
            setPageData(resp);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
            setLoadError(true);
        });
    }, [setPageData, setLoading, setLoadError, loadError])

    useEffect(() => {
        loadData().then(r => r)
    }, [loadData])

    if (loadError) return (
        <div className="text-center sm:text-left">
            There was an error loading this page, please click <span onClick={loadData} className="text-everflow-magenta underline cursor-pointer">here</span> to try again.
        </div>
    )

    if (loading) return <SkeletonPage />

    const location = pageData.location;
    const meters = pageData.meters;

    var address = [];
    if (location.data.premisesAddressLineOne) address.push(location.data.premisesAddressLineOne)
    if (location.data.premisesAddressLineTwo) address.push(location.data.premisesAddressLineTwo)
    if (location.data.premisesAddressLineThree) address.push(location.data.premisesAddressLineThree)
    if (location.data.premisesAddressLineFour) address.push(location.data.premisesAddressLineFour)
    if (location.data.premisesAddressLineFive) address.push(location.data.premisesAddressLineFive)
    if (location.data.premisesAddressLinePostcode) address.push(location.data.premisesAddressLinePostCode)
    address = address.join(', ')

    const goLive = location.data.goLiveDate ? moment(location.data.goLiveDate).format('DD.MM.YYYY') : 'Unknown';

    return (

        <div>
            <div>
                <button
                    id="yourmeters-backtolocations-btn"
                    onClick={() => history.push('/dashboard/meters')}
                    className="h-50px text-everflow-magenta"
                    href="/dashboard/meters">
                    <p className="font-bold text-lg">&lt; Back to locations</p>
                </button>
                <h2 className="text-everflow-black mb-40px font-bold text-2xl">Your meters</h2>
            </div>

            <div className="p-4 rounded-18px border-everflow-grey25 border-2 text-left flex flex-row justify-between mb-40px">
                <div className="flex flex-col items-start justify-around flex-1 md:flex-row md:items-center gap-2">
                    <div>
                        <div className="text-everflow-grey text-12px">SPID</div>
                        <div id="LocationSPID" className="text-everflow-black font-bold">{location.data.spid}</div>
                    </div>
                    <div>
                        <div className="text-everflow-grey text-12px">SPID Address</div>
                        <div id="LocationAddress" className="text-everflow-black font-bold">{address}</div>
                    </div>
                    <div>
                        <div className="text-everflow-grey text-12px">Switch Date</div>
                        <div id="SwitchDate" className="text-everflow-black font-bold">{goLive}</div>
                    </div>
                </div>
                <div className="flex-1 max-w-100px md:max-w-60px">
                    <WaterIcon className="" />
                </div>
            </div>

            <div>
                <ul className="flex flex-col">
                    <li className="hidden text-12px uppercase border-b-2 border-everflow-black justify-center md:inline-flex gap-10px text-everflow-black">
                        <div className="mb-2 w-130px">Meter ID</div>
                        <div className="mb-2 flex-grow">Meter Location</div>
                        <div className="mb-2 w-130px">Last Read Date</div>
                        <div className="mb-2 w-130px">Last Read Value</div>
                        <div className="mb-2 w-220px"></div>
                    </li>
                    {meters.map((item, index) => (
                        <MeterEntry
                            key={`meter-${item.spidMeterId}`}
                            index={index}
                            item={item}
                            expanded={expanded == item.spidMeterId}
                            setExpanded={setExpanded}
                        />
                    ))}
                    {!meters.length ? (
                        <li className="mt-20px" style={{ border: 0 }}>No meters to show.</li>
                    ) : null}
                </ul>
            </div>
        </div>
    )
}

export default EditMeter