import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { InformationLog } from '../../../Shared/ServerLogging';

const Cancel = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const merchantReference = searchParams.get('id');

    InformationLog(`Cancel page hit for merchantReference: ${merchantReference}`);

    useEffect(() => {
        window.top.scrollTo(0, 0)
    }, [])

    return (
        <>{
            <div id="cardpayment-submitted-wrapper" className="grid grid-cols-1 pt-20px pb-20px min-h-screen bg-everflow-white">
                <div className="flex flex-col p-4 text-center text-everflow-black">
                    <h2 className="pb-10px text-everflow-black">Payment Cancelled</h2>
                    <p className="pt-10px">
                        <strong>
                            <a
                                href="/dashboard/home"
                                target="_parent"
                                className="m-auto w-full mt-40px sm:w-200px h-50px bg-everflow-magenta rounded-25px box-border border-2 border-white text-everflow-white flex justify-center items-center">
                                Back to your dashboard
                            </a>
                        </strong>
                    </p>
                </div>
            </div>
        }</>
    );
}

export default Cancel