import React, { useCallback, useEffect, useRef, useState } from 'react';
import SearchBox from './SearchBox';
import Pagination from '../../../../Shared/Pagination';
import { searchSPIDs } from '../../../../../lib/api/premises';
import { SkeletonText } from '../../../../Shared/Skeletons';
import { useDebounce } from '../../../../../lib/hooks/useDebounce';
import { Link } from "react-router-dom";
import { ReactComponent as Plus } from '../../../../Shared/svgs/plus.svg'

const SearchMeters = ({ search, setSearch, page, setPage, results, setResults, helpLink }) => {
    const [startSearch, setStartSearch] = useDebounce(300, '');
    const [loadError, setLoadError] = useState();
    const [loading, setLoading] = useState();

    const searchLoader = useRef();

    const searchRef = useRef();
    searchRef.current = {startSearch, page}

    const doSearch = useCallback(()=>{
        if(results &&
            results.request.search === search &&
            results.request.page === page
        ) return;

        if(!results || results.request.search !== searchRef.current.startSearch){
            searchLoader.current && searchLoader.current.startTimer();
        }

        setLoading(1);

        searchSPIDs(startSearch, page)
            .then(resp => {
                if(resp.request.search  === searchRef.current.startSearch && resp.request.page === searchRef.current.page){
                    setLoading(false)
                    if(!results || results.request.search !== searchRef.current.startSearch)  searchLoader.current.endTimer();
                    setResults(resp);
                    if(loadError) setLoadError(false);

                }else{
                    setLoading(false)
                    if(loadError) setLoadError(false);
                }
            })
            .catch(err => {
                searchLoader.current.endTimer();
                setLoading(false)
                setLoadError(true);
            })

    }, [results, search, page, startSearch, setLoading, searchSPIDs, setResults, setLoadError, loadError])

    useEffect(doSearch, [startSearch, page])

    useEffect(() =>
    {
        setStartSearch(search)
    }, [search])

    const skeletonHelper = results && results.data && results.data.data && results.data.data.results && results.data.data.results.length ? results.data.data.results : [1];

    return (
        <>
            <div className="mt-20px w-full bg-everflow-white border-everflow-grey-25 border-2 text-everflow-black p-8 rounded-lg">
                <h3 className="text-everflow-black font-bold mb-20px">Search for your location</h3>
                <p className="mb-20px leading-6">Enter your SPID (this is your Supply Point number, which can be found on your water bills), or postcode below. As long as the premises is not 'Unmetered', you will find out where your meter is located.</p>
                <SearchBox
                    id="your-meters-input-search-box"
                    value={search}
                    searchLoader={searchLoader}
                    onChange={ev => { setPage(1); setSearch(ev.target.value) }}
                    label="Enter SPID or Postcode"
                    req
                />
            </div>
            <div className="mt-30px">
                <div className="mb-20px">
                    <h3 className="text-everflow-black font-bold mb-10px">Your locations</h3>
                    <p className="text-everflow-black">Need help, <a id="your-meters-link-faq" href={helpLink} target="_blank" rel="noopener noreferrer" className="text-everflow-magenta font-bold">click here</a></p>
                </div>
                {loadError ? (
                    <div className="mt-20px text-center sm:text-left">There was an error loading this page, please click <span onClick={doSearch} className="text-everflow-magenta underline cursor-pointer">here</span> to try again.</div>
                ) : (
                    <>
                        {results && results.data && results.data.data ? (
                            <>
                                {loading ? (
                                    <ul className="mt-20px rounded-28px border-2 border-gray-default-500 divide-gray-default-500 divide-y-2 overflow-hidden animate-pulse">
                                        {skeletonHelper.map((i, index) => {
                                            const width  = index % 2 == 0 ? 'w-200px' : 'w-300px';
                                            return(
                                                <li key={i.spid} className="px-28px h-56px flex items-center"><SkeletonText spacing={"0px"} noPulse className="w-100px"/><span className="flex-grow mx-5px"><SkeletonText spacing={"0px"} noPulse className={width}/></span><span><SkeletonText spacing={"0px"} noPulse className="w-100px"/></span></li>
                                            )})}
                                    </ul>
                                ) : (
                                    <>
                                        {!results.data.data.results.length ? (
                                            <div className="mt-40px text-center text-everflow-magenta">No results to show.</div>
                                        ) : (
                                                <ul className="mt-20px space-y-10px overflow-hidden">
                                                {results.data.data.results.map((i, index) => {
                                                    var address = [];
                                                    if(i.premisesAddressLineOne) address.push(i.premisesAddressLineOne)
                                                    if(i.premisesAddressLineTwo) address.push(i.premisesAddressLineTwo)
                                                    if(i.premisesAddressLineThree) address.push(i.premisesAddressLineThree)
                                                    if(i.premisesAddressLineFour) address.push(i.premisesAddressLineFour)
                                                    if(i.premisesAddressLineFive) address.push(i.premisesAddressLineFive)
                                                    if (i.premisesPostcode) address.push(i.premisesPostcode)
                                                    const hasMeters = (i.metersOnSPID > 0);
                                                    const label = hasMeters ? 'View meter' : 'Unmetered';
                                                    const linkUri = hasMeters ? `/dashboard/meters/${i.spid}` : `#`;
                                                    const linkId = `your-meters-button-view-meter-${i.spid}`
                                                    if (hasMeters)
                                                    {
                                                        return (
                                                            <li key={i.spid} className="my-10px p-2 bg-everflow-magenta text-everflow-white rounded-lg border-2 border-everflow-magenta">
                                                                <div className="grid grid-cols-5">
                                                                    <div className="grid grid-rows-2 gap-2">
                                                                        <div>
                                                                            <p className="flex-grow mx-5px">SPID</p>
                                                                        </div>
                                                                        <div className="overflow-hidden whitespace-nowrap">
                                                                            <p id={`SPID-${i.spid}-ID`} className="flex-grow overflow-ellipsis overflow-hidden mx-5px font-bold">{i.spid}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-start-2 col-end-5 grid grid-rows-2 gap-2">
                                                                        <div>
                                                                            <p className="flex-grow mx-5px">Address</p>
                                                                        </div>
                                                                        <div className="overflow-hidden whitespace-nowrap">
                                                                            <p id={`SPID-${i.spid}-Address`} className="flex-grow overflow-ellipsis overflow-hidden mx-5px font-bold">{address.join(', ')}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-5px">
                                                                        <Link id={linkId} to={linkUri} className="cursor-pointer h-full w-full flex items-center rounded-25px justify-center text-everflow-black bg-everflow-green font-bold">
                                                                            <span className="flex flex-grow-0 flex-shrink-0">
                                                                                <span className="hidden md:block">{label}</span>
                                                                                <Plus className="w-16px md:hidden" />
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                    else
                                                    {
                                                        return (
                                                            <li key={i.spid} className="my-10px p-2 bg-everflow-magenta text-everflow-white rounded-lg border-2 border-everflow-magenta">
                                                                <div className="grid grid-cols-5">
                                                                    <div className="grid grid-rows-2 gap-2">
                                                                        <div>
                                                                            <p className="flex-grow mx-5px">SPID</p>
                                                                        </div>
                                                                        <div className="overflow-hidden whitespace-nowrap">
                                                                            <p className="flex-grow overflow-ellipsis overflow-hidden mx-5px font-bold">{i.spid}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-start-2 col-end-5 grid grid-rows-2 gap-2">
                                                                        <div>
                                                                            <p className="flex-grow mx-5px">Address</p>
                                                                        </div>
                                                                        <div className="overflow-hidden whitespace-nowrap">
                                                                            <p className="flex-grow overflow-ellipsis overflow-hidden mx-5px font-bold">{address.join(', ')}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-5px">
                                                                        <div className="h-full w-full items-center rounded-25px justify-center text-everflow-white hidden md:flex">
                                                                            <p className="flex flex-grow-0 flex-shrink-0 font-bold italic">
                                                                                {label}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                })}
                                            </ul>
                                        )}
                                    </>
                                )}
                                <div className="text-center">
                                    <Pagination
                                        className="mx-auto mt-40px"
                                        pages={results.data.data.maxPage}
                                        selected={page}
                                        callback={setPage}
                                    />
                                </div>
                            </>
                        ) : null}

                    </>
                )}
            </div>
        </>
    )
}

export default SearchMeters;