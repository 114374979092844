
class DeviceAndBrowserDetector {

    userAgent = "";
    productName = "";
    operatingSystem = "";

    constructor() {
        this.userAgent = navigator.userAgent;
        this.productName = this.#getBrowserProduct();
        this.operatingSystem = this.#getOperatingSystem();
    }

    #getOperatingSystem = () => {
        if ('userAgentData' in navigator) {
            const uaData = navigator.userAgentData;
            const platform = uaData.platform;
            if (platform.includes('Windows')) {
                return "Windows";
            }
            if (platform.includes('macOS')) {
                return "macOS";
            }
            if (platform.includes('Android')) {
                return "Android";
            }
            if (platform.includes('iOS')) {
                return "iOS";
            }
            if (platform.includes('Linux')) {
                return "Linux";
            }
        }
        return this.#getOperatingSystemByOtherMeans();
    }

    #getOperatingSystemByOtherMeans = () => {

        if (/Windows/.test(this.userAgent)) {
            return "Windows";
        }
        else if (/iPad|iPhone|iPod|Macintosh|Mac OS|iOS/.test(this.userAgent) && !window.MSStream) {
            return "Apple";
        }
        else if (/Linux/.test(this.userAgent)) {
            return "Linux";
        }
        else if (/Android/.test(this.userAgent)) {
            return "Android";
        }
        return "Unknown";
    }

    #getBrowserProduct = () => {
        if (/Chrome/.test(this.userAgent) && !/Edg/.test(this.userAgent) && !/OPR/.test(this.userAgent)) {
            return "Chrome";
        }
        if (/Firefox/.test(this.userAgent)) {
            return "Firefox";
        }
        if (/Safari/.test(this.userAgent) && !/Chrome/.test(this.userAgent) && !/Edg/.test(this.userAgent)) {
            return "Safari";
        }
        if (/Edg/.test(this.userAgent)) {
            return "Edge";
        }
        if (/MSIE|Trident/.test(this.userAgent)) {
            return "Internet Explorer";
        }
        if (/OPR/.test(this.userAgent)) {
            return "Opera";
        }
        return "Unknown";
    }
}

export default DeviceAndBrowserDetector