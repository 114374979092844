import React from "react";
import {ReactComponent as LoadingTick} from '../svgs/loading-tick.svg'

const TickAnim = ({size}) => {
    if(!size) size = "20px";
    
    return (
        <div className="tick-anim" style={{width: size}}>
            <LoadingTick />
        </div>
    )
}
export default TickAnim