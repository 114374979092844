import React, { useState } from 'react';
import { useDebounce } from '../../../../../lib/hooks/useDebounce';
import {ReactComponent as Tick} from '../../../../Shared/svgs/tick.svg'
import TickAnim from '../../../../Shared/TickAnim';

const InputText = React.forwardRef(({onChange, value, label, password, onClick, className, readOnly, tick, cross, req, error, id, onBlur, onFocus, loadingTick, thin}, ref) => {

    const [active, setActive] = useDebounce(100);
    
    const focus = active || !!value;

    const classes = [
        'input-text'
    ]
    
    if(className) classes.push(className);
    if(focus) classes.push('focus');


    if (tick) classes.push('tick');
    if (error || cross) classes.push('cross');

    console.log('error input', error)

    return (
        <div className={classes.join(' ')} ref={ref}>
            <div className="input-text-wrapper">
                {label ? (
                    <label>{label}{req ? (<span className="text-everflow-magenta"> *</span>) : null}</label>
                ) : null}
                {tick ? (
                    <div className="absolute top-1/2 right-28px transform -translate-y-1/2 flex">
                        <Tick className="w-16px"/>
                    </div>
                ) : null}
                {loadingTick ? (
                    <div className="absolute top-1/2 right-10px transform -translate-y-1/2 flex">
                        <TickAnim size="40px"/>
                    </div>
                ) : null}
                <input
                    style={thin ? { height: '50px' } : {}}
                    id={id}
                    onChange={onChange}
                    onClick={onClick}
                    value={value}
                    type={password ? 'password' : 'text'}
                    readOnly={!!readOnly}
                    onBlur={ev => onBlur && onBlur(ev)}
                    className={className}
                />
            </div>
            {error ? (
                <div className="px-28px py-10px text-everflow-magenta text-13px">{error}</div>
            ) : null}
        </div>
    )
})



export default InputText