const timeout = 30;
const retries = 3;
const delay = 2;

export const rateHandler = (task) => {
    return new Promise(async (res, rej) => {

        console.log('ratelimit start')

        var response;
        for(var i = 0; i < (retries + 1); i++) {
            try{

                showRetry('requesting', {try: i, of: retries});

                if(i > 0) await new Promise(res => setTimeout(res, delay * 1000));

                
                response = await new Promise(task);

                showRetry('hide');

                return res(response);
            }catch(err){

                if(!(err && err.data && err.status === 429)) {
                    showRetry('hide');
                    return rej(err);
                }
                showRetry('show');

                if(i < retries){

                    showRetry('startTimer', timeout)
                    await new Promise(res => setTimeout(res, timeout * 1000));
                }
            }
        }

        showRetry('failed')
        
        // add fallback form response
        rej({
            success: false,
            formStatus: 'Server busy'
        });
    })
}

const showRetry = (action, data) => {
    const event = new CustomEvent('retryMessage', { detail: {action, data} });
    window.dispatchEvent(event);
} 
