import React from 'react';

const WhiteButton = ({ id, children, selected, onClick }) =>
{
    return (
        <div id={id} onClick={onClick} className={`${selected ? 'bg-everflow-magenta text-everflow-white' : 'bg-everflow-white text-everflow-magenta'} h-90px inline-flex items-center px-30px rounded-18px text-20px cursor-pointer border-2 border-everflow-magenta`}>
            {children}
        </div>
    )
}

export default WhiteButton