import {useState, useEffect} from 'react';

export const useDebounce = (delay, initVal) => {
    if(!delay) delay = 500

    const [string, setString] = useState();
    const [debounced, setDebounced] = useState(initVal);

    useEffect(()=>{
        const timeout = setTimeout(()=>{
            setDebounced(string)
        }, delay)

        return () => {
            clearTimeout(timeout);
        }
    }, [string, delay])

    return [debounced, setString];
}