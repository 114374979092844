import { InformationLog } from '../../components/Shared/ServerLogging';
import { useEffect, useState } from "react";
import { useHistory } from "react-router"

export const useLogin = () =>
{
    const [loggedIn, setLoggedIn] = useState({})
    const [init, setInit] = useState();
    const history = useHistory();
    const path = history.location.pathname

    useEffect(() =>{
        var data = localStorage.getItem('account');
        try {
            data = JSON.parse(data);
            if(!data) data = {};
        }catch(err){
            data = {}
        }

        setLoggedIn(data);
        setInit(true)
    }, [path])

    return {loading: init ? false : true, account: loggedIn};
}