import React from 'react';

export function PoundSymbol()
{
    var formattedValue = `&pound;`;
    return <span dangerouslySetInnerHTML={{ __html: formattedValue }} />;
};

export function PoundCurrency(value)
{
    var formattedValue;
    var floatVal = parseFloat(value);

    if (floatVal < 0)
    {
        formattedValue = `-&pound;${Math.abs(floatVal).toFixed(2)}`
    }
    else
    {
        formattedValue = value ? `&pound;${floatVal.toFixed(2)}` : `&pound;0.00`;
    }

    return <span dangerouslySetInnerHTML={{ __html: formattedValue }} />;
};

export default PoundSymbol