import React, {useEffect, useState} from "react";
import Pagination from '../../../../Shared/Pagination';
import {getBills} from "../../../../../lib/api/billing";
import moment from 'moment';
import BillsSkeleton from "../Skeleton";
import {ReactComponent as Dl} from '../../../../Shared/svgs/download.svg'
import ReactTooltip from 'react-tooltip';
import DocumentDownloader from "../../../../../lib/api/documentdownloader";
import InputSelect2 from "../../../../Shared/FormComponents/InputSelect2";
import { PoundCurrency } from '../../../../Shared/EncodingManipulation/index';
import { ErrorLog } from '../../../../Shared/ServerLogging';

const amountPerPage = 10;

const Bills = ({ helpLink }) => {
    const [loadError, setLoadError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [bills, setBills] = useState(null);
    const [page, setPage] = useState(1);
    const [year, setYear] = useState(new Date().getFullYear());

    const loadBills = () => {
        setLoading(true);
        getBills(page, year, amountPerPage)
            .then(response => {
                if (response && response.data && response.data.data) {
                    setBills(response.data.data);
                    setLoadError(false);
                } else {
                    setLoadError(true);
                }
            })
            .catch(error =>
            {
                ErrorLog('An error occurred while loading bills:', error);
                setLoadError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadBills();
    }, [page, year]);

    const yearRange = (() => {
        const dates = [];
        for (let i = new Date().getFullYear(); i >= 2021; i--) dates.push(i)
        return dates.map(i => ({label: i, value: i}));
    })()

    return (
        <>
            <div className="flex flex-col sm:flex-row sm:items-center">
                <p className="sm:mr-80px mt-20px sm:mt-0">If you have any questions on your bill. <a href={helpLink || '#'} target="_blank" rel="noopener noreferrer" className="underline text-everflow-magenta font-bold">Check out our Help and FAQs section</a> </p>
                <InputSelect2
                    className="mt-20px ml-auto sm:mt-0 w-full sm:w-320px"
                    placeholder="Select Year"
                    id="BillsYearSelection"
                    value={year}
                    options={yearRange}
                    onChange={setYear}
                />
            </div>
            {loading ? (
                <BillsSkeleton
                    rows={bills && bills.results && bills.results.length || amountPerPage}
                    pages={bills && bills.maxPage}
                    selected={bills && bills.paging && bills.paging.pageNumber}
                />
            ) : (
                <>
                    {loadError ? (
                        <div className="text-center sm:text-left">
                            There was an error loading this page, please click <span onClick={loadBills} className="text-everflow-magenta underline cursor-pointer">here</span> to try again.
                        </div>
                    ) : (
                        <BillsResults bills={bills} setPage={setPage} />
                    )}
                </>
            )}
        </>
    )
}
const BillsResults = ({ bills, setPage }) => {

    if (!bills || !bills.results) return null;

    if (!bills.results.length) return (
        <div className="text-everflow-magenta text-center">No billing data to show.</div>
    )

    const getDocument = async (id) => {     
        const downloader = new DocumentDownloader();
        await downloader.downloadDocumentUsingBase64(document, id);
    };
    return (
        <>
            <div className="overflow-auto">
                <ul className="min-w-660px text-left divide-y-2 divide-gray-default-500">
                    <li className="h-62px border-b-2 border-everflow-magenta text-12px uppercase flex items-center font-bold text-center">
                        <div className="w-1/5 pr-20px text-left">Invoice No.</div>
                        <div className="w-1/5 pr-20px">Date</div>
                        <div className="w-1/5 pr-20px">Bill Period</div>
                        <div className="w-1/5 pr-20px">Total Charge</div>
                        <div className="w-1/5 text-right">Download</div>
                    </li>
                    {bills.results.map((item, index) => {
                        const strike = item.rebilled || item.removed;
                        return (
                            <li key={`bill-${index}-${item.id}`} style={!index ? { borderTop: 0 } : {}} className="flex text-center h-56px items-center">
                                <div id={`Bill${index}InvoiceNo`} className={`w-1/5 pr-20px text-left${strike ? ' line-through' : ''}`}>{item.invoiceNumber}</div>
                                <div id={`Bill${index}Date`} className={`w-1/5 pr-20px${strike ? ' line-through' : ''}`}>{moment(item.dateGenerated).format('DD.MM.YYYY')}</div>
                                <div id={`Bill${index}BillPeriod`} className={`w-1/5 pr-20px${strike ? ' line-through' : ''}`}>{moment(item.dateFrom).format('DD.MM.YYYY')} - {moment(item.dateTo).subtract(1, 'days').format('DD.MM.YYYY')}</div>
                                <div id={`Bill${index}TotalCharge`} className={`w-1/5 pr-20px${strike ? ' line-through' : ''}`}>{PoundCurrency(item.total)}</div>
                                <div className="w-1/5 text-right">
                                    <div className="inline-block">
                                        <div className="grid grid-cols-2 gap-10px">
                                            {item.pdfId ? (
                                                <span id={`Bill${index}PdfDownload`} data-tip={strike ? 'Bill no longer active' : ''} onClick={async () => await getDocument(item.pdfId)}
                                                    className={`inline-flex flex-col items-center cursor-pointer`}>
                                                    <Dl className="w-16px fill-current" />
                                                    <span className="text-10px font-bold">PDF</span>
                                                </span>
                                            ) : (
                                                    <span id={`Bill${index}PdfNotReady`} data-tip="Bill is not ready to download" className={`cursor-pointer inline-flex flex-col items-center text-grayText-default-300`}>
                                                    <Dl className="w-16px fill-current" />
                                                    <span className="text-10px font-bold">PDF</span>
                                                </span>
                                            )}
                                            {item.csvId ? (
                                                <span id={`Bill${index}CsvDownload`} data-tip={strike ? 'Bill no longer active' : ''} onClick={async () => await getDocument(item.csvId)}
                                                    className={`inline-flex flex-col items-center cursor-pointer`}>
                                                    <Dl className="w-16px fill-current" />
                                                    <span className="text-10px font-bold">CSV</span>
                                                </span>
                                            ) : (
                                                    <span id={`Bill${index}CsvNotReady`} data-tip="Bill is not ready to download" className={`cursor-pointer inline-flex flex-col items-center text-grayText-default-300`}>
                                                    <Dl className="w-16px fill-current" />
                                                    <span className="text-10px font-bold">CSV</span>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
                <ReactTooltip />
            </div>
            {bills.maxPage > 1 ? (
                <div className="text-center mt-80px">
                    <Pagination
                        pages={bills.maxPage}
                        selected={bills.paging.pageNumber}
                        callback={setPage}
                    />
                </div>
            ) : null}
        </>
    )
}
export default Bills;