import React, { useState, useRef, useEffect } from 'react';
import {ReactComponent as DropDown} from '../../svgs/dropdown.svg';

const InputSelect = ({placeholder, options, onChange, disabled, className, value, id}) => {
    const [open, setOpen] = useState();
    const ref = useRef();
    const listRef = useRef();
    const contRef = useRef();

    useEffect(()=>{
        const clickOff = e => {
            if(!open) return;
            if(!ref.current.contains(e.target)){
                console.log('click off')
                setOpen(false)
            }
        }
        window.addEventListener('click', clickOff);
        return () => {
            window.removeEventListener('click', clickOff);
        }
    }, [ref, open])
    
    useEffect(()=>{
        if(!listRef && listRef.current) return;
        if(!contRef && contRef.current) return;
        
        var height = 0;
        if(open) height = listRef.current.clientHeight;

        contRef.current.style.height = (height + 4) + 'px'



    }, [open])

    const setSelected = val => {
        setOpen(false);
        onChange(val);
    }

    const selected = value && options && options.find(i => i.value == value);

    console.log('input disabled', disabled)

    return (
        <div className={`input-select2${open ? ' active' : ''}${disabled ? ' pointer-events-none' : ''}${className ? ' ' + className : ''}`} ref={ref} id={id}>
            <div className={`selected transition-all border-2 ${disabled ? 'text-gray-default-500' : 'border-gray-default-500 hover:border-everflow-magenta'} ${open ? 'border-everflow-magenta' : ''}`} onClick={() => !disabled && setOpen(!open)}>
                {selected ? selected.label : placeholder}
                <span className="dropdown-icon flex">
                    <DropDown
                        style={open ? {transform: 'scaleY(-1)'} : {}}
                        className={`${disabled ? 'fill-current text-gray-default-500' : ''} ${open ? 'text-everflow-magenta' : ''}`}
                    />
                </span>
            </div>
            <div className="absolute w-full top-76px left-0">
                <div className={`relative overflow-hidden transition-all border-2 border-transparent rounded-28px ${open ? ' border-everflow-magenta' : ''}`} ref={contRef}>
                    <ul className="absolute" ref={listRef}>
                        {options && options.map((i, index) => (
                            <li id={ id ? `${id}${i.value}` : null} key={`${index}-${i.value}`} onClick={()=>setSelected(i.value)}>{i.label}</li>
                        ))}
                    </ul>
                </div>
            </div>
            <select value={value} disabled={disabled} onChange={ev => setSelected(ev.target.value)}>
                {options && options.map((i, index) => (
                    <option key={`${index}-${i.value}`} value={i.value}>{i.label}</option>
                ))}
            </select>
        </div>
    )
}

export default InputSelect;