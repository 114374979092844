import React from 'react';
import {ReactComponent as Chevron} from '../../Shared/svgs/chevron-left.svg'

const Pagination = ({pages, selected, callback, className}) => {

    if(!pages || pages === 1) return null;
    
    if(!selected) selected = 1;

    // build array or all pages
    var buttons = []
    for(var i = 1; i <= pages; i++) buttons.push(i);

    // check which pages to display, if more than 5
    buttons = (() => {

        if(pages <= 5) return buttons;
        
        // if selected in first 3
        if(selected < 4) return [...buttons.slice(0, 5), '...']

        // if selected in last 3
        if((pages - selected) < 3) return ['...', ...buttons.slice(pages - 5, pages)]

        return ['...', ...buttons.slice(selected - 3, selected + 2), '...']
    })()
        
   
    return (
        <div className={`inline-flex items-center${className ? ' ' + className : ''}`}>
            <button onClick={() => {if(selected > 1) callback(selected - 1)}} className={`mr-40px w-50px h-50px border ${selected > 1 ? 'border-everflow-magenta text-everflow-magenta' : 'border-gray-default-500 text-gray-default-500 pointer-events-none'} rounded-25px flex flex-col items-center justify-center`}><Chevron className="fill-current" /></button>
            {buttons.map((i, index) => {

                // if selected
                if(i == selected) return <span key={`b-${index}`} className=" w-50px h-50px border border-everflow-magenta rounded-25px flex flex-col items-center justify-center bg-everflow-magenta text-white">{i}</span>
                
                // if first dots
                if(i == '...' && index == 0) return <span key={`b-${index}`}  style={{color: '#747385'}} onClick={() => callback(1)}className="w-50px h-50px border border-transparent rounded-25px flex flex-col items-center justify-center hover:underline cursor-pointer">{i}</span>
                // if last dots
                if(i == '...' && index == buttons.length - 1) return <span key={`b-${index}`}  style={{color: '#747385'}} onClick={() => callback(pages)}className="w-50px h-50px border border-transparent rounded-25px flex flex-col items-center justify-center hover:underline cursor-pointer">{i}</span>

                // default
                return <span key={`b-${index}`}  style={{color: '#747385'}} onClick={() => callback(i)}className="w-50px h-50px border border-transparent rounded-25px flex flex-col items-center justify-center hover:underline cursor-pointer">{i}</span>
            })}

            <button onClick={() => {if(selected < pages) callback(selected + 1)}} className={`ml-40px w-50px h-50px border ${selected < pages ? 'border-everflow-magenta text-everflow-magenta' : 'border-gray-default-500 text-gray-default-500 pointer-events-none'} rounded-25px flex flex-col items-center justify-center`}><Chevron className="fill-current" style={{transform: 'scaleX(-1)'}} /></button>
        </div>
    )
}

export default Pagination;