import React, {useEffect} from 'react';
import { Switch, useHistory, useRouteMatch } from "react-router-dom";

function NotFoundPage({ CustomNotFoundPage }) {
  const history = useHistory();
  useEffect(() => {
    history.push("/404");
  }, []);
  return null;
}




function CustomSwitch({location, key, children}) {
  return (
    <Switch location={location} key={key}>
      {children}
      <NotFoundPage />
    </Switch>
  );
}

export default CustomSwitch;